import { put, takeEvery, all, select, call, delay } from 'redux-saga/effects'
import Numeral from 'numeral'
import * as commonCreators from 'src/redux/ducks/commons/creators'
import * as preOrderPageActionCreator from './creators'
import * as preOrderPageSelectors from './selectors'
import * as preOrderPageActions from './actions'

import * as signUpCommonsSelectors from 'src/redux/ducks/screens/signup/commons/selectors'
import * as signUpCommonActionCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as menuDataselectorMenuList from 'src/redux/ducks/modules/MenuList/selectors'

import * as signUpUtils from 'src/redux/ducks/screens/signup/utils'

import { SESSION_KEY } from 'src/constants'
import { generateRandom } from 'src/utils/experiments'
import { GQL } from 'src/api/services'

const getApplicationState = () => select(state => state)

const getCartTrackingEventData = (startDay, mealsPerDelivery, products) => {
  const totalProducts = signUpUtils.sumAllQty(products)
  const cartTrackingData = {
    delivery_date: startDay.date,
    plan_meals: mealsPerDelivery,
    cart_meals_quantity: totalProducts,
    cart_plan_qty: `${totalProducts}/${mealsPerDelivery}`,
    cart_plan_diff: totalProducts - mealsPerDelivery,
    products: [],
  }

  cartTrackingData.products = products.map(product => {
    return {
      id: product.data.entity_id || product.data.mealExternalId,
      category: product.data.category_id || product.data.categoryId,
      chef_id: product.data.chef_id || product.data.chef?.id,
      chef_name: `${product.data.chef_firstname ||
        product.data.chef?.firstName} ${product.data.chef_lastname ||
        product.data.chef?.lastName}`,
      image_url : (product.data.image_full_url || product.data.primaryImageUrl) ?? '',
      name: product.data.name,
      price: product.data.price,
      rating: product.data.stars,
      sku: product.data.sku,
    }
  })

  return cartTrackingData
}

const selectUniqueMealsFromList = (
  mealsToSelect,
  mealsList,
  selectedMeals,
  mealsListLimit,
) => {
  const mealsToAdd = []
  let mealToAdd
  const maxMealListLimit =
    mealsListLimit < mealsList.length ? mealsListLimit : mealsList.length
  for (let i = 0; i < mealsToSelect; i++) {
    mealToAdd = mealsList[generateRandom(maxMealListLimit)]
    if (
      // eslint-disable-next-line no-loop-func
      (!mealsToAdd.some(({ entity_id }) => entity_id === mealToAdd.entity_id) &&
        !selectedMeals.some(
          // eslint-disable-next-line no-loop-func
          selectedProduct => selectedProduct.id === mealToAdd.entity_id,
        )) ||
      mealsToAdd.length + selectedMeals.length >= mealsList.length
    ) {
      mealsToAdd.push(mealToAdd)
    } else {
      i--
    }
  }
  return mealsToAdd
}

export function* addProductCart({ payload }) {
  const applicationState = yield getApplicationState(),
    mealData = payload,
    entryMealId = mealData.entity_id || mealData.mealExternalId,
    userSelectedData = signUpCommonsSelectors.getUserSelectedData(
      applicationState,
    ),
    { mealsPerDelivery, price, deliveries } = userSelectedData.selectedPlan,
    { products } = preOrderPageSelectors.getCart(applicationState),
    existsInsideTheCart = products.find(p => p.id === entryMealId),
    totalProductQty = signUpUtils.sumAllQty(products),
    isAvailableLimit =
      totalProductQty < mealsPerDelivery,
    { startDay } = userSelectedData

  const pricePerMeal = price / (mealsPerDelivery * deliveries)

  if (window.google_tag_manager) {
    const imageFullUrl = mealData.primaryImageUrl ?? ''
    let dataLayer = window.dataLayer || []
    dataLayer.push({
      event: 'addToCart',
      MealName: `${mealData.name}`,
      MealId: entryMealId,
      MealInventoryId: mealData.inventoryId,
      MealPrice: Numeral(pricePerMeal).format('0.00'),
      MealImage: imageFullUrl,
      QuizRecommendation: mealData.quizRecommendation || false,
      PositionInMenu: mealData.position_in_menu || 0,
    })
  }

  if (isAvailableLimit) {
    let newProducts = []
    const productQty = totalProductQty + 1
    const planSizeExceeded = productQty > mealsPerDelivery
    if (!existsInsideTheCart) {
      products.push({
        id: entryMealId,
        qty: 1,
        data: mealData,
        exceededUnits: planSizeExceeded ? 1 : undefined,
      })
      newProducts = [...products]
    } else if (existsInsideTheCart) {
      newProducts = products.map(product =>
        product.id === entryMealId
          ? {
              ...product,
              qty: existsInsideTheCart.qty + 1,
              exceededUnits: planSizeExceeded
                ? (product.exceededUnits || 0) + 1
                : undefined,
            }
          : product,
      )
    }

    yield put(
      preOrderPageActionCreator.addCart({
        products: newProducts,
        totalProductQty: signUpUtils.sumAllQty(newProducts),
        planSizeExceeded,
      }),
    )

    yield put(
      commonCreators.trackingStart({
        eventName: 'Cart Updated',
        eventData: getCartTrackingEventData(
          startDay,
          mealsPerDelivery,
          newProducts,
        ),
      }),
    )
  }
  yield put(signUpCommonActionCreators.persistState({ sessionId: SESSION_KEY }))
}

export function* removeProductCart({ payload }) {
  const applicationState = yield getApplicationState(),
    mealData = payload,
    entryMealId = mealData.entity_id || mealData.mealExternalId,
    { products } = preOrderPageSelectors.getCart(applicationState),
    totalProductQty = signUpUtils.sumAllQty(products),
    existsInsideTheCart = products.find(p => p.id === entryMealId),
    userSelectedData = signUpCommonsSelectors.getUserSelectedData(
      applicationState,
    ),
    { mealsPerDelivery } = userSelectedData.selectedPlan,
    planSizeExceeded =
      totalProductQty > mealsPerDelivery,
    { startDay } = userSelectedData

  let newProducts = []
  if (existsInsideTheCart) {
    if (existsInsideTheCart.qty > 1) {
      newProducts = products.map(product => {
        if (product.id === entryMealId) {
          let newExceededUnits
          if (planSizeExceeded) {
            const updatedUnits = (product.exceededUnits || 0) - 1
            newExceededUnits = updatedUnits > 0 ? updatedUnits : undefined
          }

          return {
            ...product,
            qty: existsInsideTheCart.qty - 1,
            exceededUnits: newExceededUnits,
          }
        }

        return product
      })
    } else if (existsInsideTheCart.qty === 1) {
      newProducts = products.filter(p => p.id !== entryMealId)
    }

    yield put(
      preOrderPageActionCreator.removeCart({
        products: newProducts,
        totalProductQty: signUpUtils.sumAllQty(newProducts),
        planSizeExceeded: totalProductQty > mealsPerDelivery,
      }),
    )

    yield put(
      commonCreators.trackingStart({
        eventName: 'Cart Updated',
        eventData: getCartTrackingEventData(
          startDay,
          mealsPerDelivery,
          newProducts,
        ),
      }),
    )
  }

  yield put(signUpCommonActionCreators.persistState({ sessionId: SESSION_KEY }))
}

export function* chooseForMe() {
  const applicationState = yield getApplicationState()

  const userSelectedData = signUpCommonsSelectors.getUserSelectedData(
    applicationState,
  )

  const {
    startDay: { date },
    zipcode,
  } = userSelectedData

  const bestSellerMenu = yield call(GQL.getMenuSortBy, {
    menuDate: date,
    sortBy: {
      bestSellers: true,
    },
    zipcode,
  })

  const menu = menuDataselectorMenuList.getMenuData(applicationState).menu
    .allMeals

  const menuRecommended = menu.filter(meal => meal.matches_preferences)

  let menuSorted = bestSellerMenu

  if (menuRecommended.length) {
    menuSorted = bestSellerMenu.filter(meal =>
      menuRecommended.some(
        mealSorted => mealSorted.entity_id === meal.entity_id,
      ),
    )
  }

  const menuFilled = menuSorted.map(mealSorted => menu.find(meal => mealSorted.entity_id === meal.entity_id))

  const cart = preOrderPageSelectors.getCart(applicationState)
  const { products, totalProductQty } = cart
  const { mealsPerDelivery } = userSelectedData.selectedPlan

  const mealsToAddCount = mealsPerDelivery - totalProductQty
  const elegibleMenu = menuFilled
    .filter(meal => meal.stock)
    .filter(meal => !meal.isPremium)

  const mealsToAdd = selectUniqueMealsFromList(
    mealsToAddCount,
    elegibleMenu,
    products,
    30,
  )

  yield delay(3000)

  for (let i = 0; i < mealsToAdd.length; i++) {
    yield call(addProductCart, {
      payload: {
        ...mealsToAdd[i],
        choseForMe: true,
      },
    })
  }

  yield put(preOrderPageActionCreator.successChooseForMe())
}

export function* undoChooseForMe() {
  const applicationState = yield getApplicationState()

  const cart = preOrderPageSelectors.getCart(applicationState)
  const { products } = cart

  const choseForMeMeals = products.filter(product => product.data.choseForMe)
  for (let i = 0; i < choseForMeMeals.length; i++) {
    yield call(removeProductCart, { payload: choseForMeMeals[i].data })
  }
}

export function* refreshChooseForMe() {
  yield* undoChooseForMe()
  yield* chooseForMe()
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(preOrderPageActions.CART.startAdd, addProductCart),
    takeEvery(preOrderPageActions.CART.startRemove, removeProductCart),
    takeEvery(preOrderPageActions.CHOOSE_FOR_ME.start, chooseForMe),
    takeEvery(preOrderPageActions.CHOOSE_FOR_ME.startUndo, undoChooseForMe),
    takeEvery(
      preOrderPageActions.CHOOSE_FOR_ME.startRefresh,
      refreshChooseForMe,
    ),
  ])
}
