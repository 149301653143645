import React, { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import alert from 'src/assets/icons/alert.png'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import Loader from 'src/components/Loader'
import { CuiButton, CuiIcon, TabsMenu } from 'front-lib'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import MealCardFromApp from '../../components/MealCardPremiumFee'

import './MenuList.scss'
import { filtersOptions, recommendationsFilterOption } from './utils'
import HighlightedRow from './Components/HighlightedRow'

import useExperiment from 'src/hooks/useExperiment'
import { isMobile } from 'src/utils/events'
import { getChooseForMeStatus } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'
import { FILTER_IDS } from './Components/FiltersMenuBy/constants'
import { CHEFS_CHOICE_BUTTON_LABEL } from './contants'
import { useFlag } from '@unleash/proxy-client-react'
import CuiSearchBar from 'src/components/CuiSearchBar/CuiSearchBar'
import { EXPERIMENTS } from 'src/constants/experiments'
import { getConfigFromNpc } from 'src/redux/ducks/modules/MenuList/utils/preselectProduct'

const MenuList = props => {
  const {
    isLoading,
    mealsMenu,
    addProduct,
    removeProduct,
    categoryList,
    onMealClick,
    handlerFilterOnClick,
    meatTypeFilter,
    specificationsDetailFilter,
    showFilterBar,
    countOrderProduct,
    maxProductToCart,
    totalOrderedProductsQty,
    modalFilters,
    showRecommendationsFilter,
    preSelectError,
    setShowQuiz,
    hasPersonalized,
    getCategory,
    getProductDataOfCart,
    actions,
    onClickChooseForMe,
    isChooseForMeLoading,
    highlightedMeals,
    menuFilters,
  } = props

  const isQueryPublicEnabled = useFlag(
    'gro-gtt-4387-enable-meal-public-query-funnel',
  )

  const { isTreatment: mealSearchBarExperiment } = useExperiment(EXPERIMENTS.mealSearchBar, {
    trackOnMount: true,
  })

  const { isTreatment: quizHoldoutIsTreatment } = useExperiment(
    EXPERIMENTS.quizHoldout,
  )

  const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
  const _filtersOptions = [...filtersOptions]

  if (showRecommendationsFilter)
    _filtersOptions.splice(1, 0, recommendationsFilterOption)

  const getOrderQty = id =>
    typeof countOrderProduct === 'function' ? countOrderProduct(id) : 0

  const meals = mealsMenu.meals

  const menuSelected = useMemo(() => menuFilters?.filterBy?.menu || {}, [
    menuFilters,
  ])

  const showHighlightedMeals = useMemo(() => {
    const { displayHighlightedRow: showFromConfig } = getConfigFromNpc()

    if (showFromConfig === 'never') {
      return false
    }

    if (showFromConfig === 'always') {
      return (
        highlightedMeals &&
        Array.isArray(highlightedMeals.meals) &&
        highlightedMeals.meals.length > 0
      )
    }

    return (
      (meatTypeFilter === 'all' || menuSelected.id === FILTER_IDS.ALL) &&
      highlightedMeals &&
      Array.isArray(highlightedMeals.meals) &&
      highlightedMeals.meals.length > 0
    )
  }, [highlightedMeals, meatTypeFilter, menuSelected.id])

  const searchInputRef = useRef();

  const resetSearch = () => {
    searchInputRef.current?.resetInput();
    const { meatTypeFilter, specificationsDetailFilter } = menuFilters;
    const id = meatTypeFilter || specificationsDetailFilter;
    const type = meatTypeFilter ? 'meatType' : 'specificationsDetail';
    handlerFilterOnClick(id, type, undefined , false, '');    
  }

  const displayClearSearch = useMemo(
    () => isMobile() && menuFilters.searchInput, [ menuFilters.searchInput ]
  );

  if (isLoading) {
    return <Loader />
  }

  return (
    <div
      className={`menu-list-container new-meal-card-design ${
        modalFilters ? 'menu-list-modal' : 'menu-list-no-modal'
      }`}
    >
      {showFilterBar && (
        <div className="cui-menu-filter container-cui">
          <TabsMenu
            showNavigation={true}
            handleOnClick={({ id, type }) => {
              handlerFilterOnClick(id, type)
            }}
            tabsItems={_filtersOptions}
            selectedTab={{ id: meatTypeFilter || specificationsDetailFilter }}
          />
        </div>
      )}

      <div className={classNames('menu-actions', 'container-cui',
          { 'control': !mealSearchBarExperiment },
        )}
      >
        <div className={classNames('menu-actions__retake-quiz',
            { 'treatment': mealSearchBarExperiment },
            { 'clear-search-visible': mealSearchBarExperiment && displayClearSearch }
          )}
        >
          <span className="menu-actions__retake-quiz--text">
            {mealSearchBarExperiment && menuFilters.searchInput
              ? `Showing ${meals.length} meals for ${menuFilters.searchInput}:`
              : `Showing ${meals.length} meals:`
            }
          </span>
          {!quizHoldoutIsTreatment && (
            <button
              onClick={() => setShowQuiz(true)}
              className="menu-actions__retake-quiz--button"
            >
              {hasPersonalized
                ? 'Update preferences'
                : 'Take quiz and show recommended meals'}
            </button>
          )}
        </div>

        {mealSearchBarExperiment &&
        <>
          {displayClearSearch && <div className="menu-actions__clear-search">
            <button
              onClick={resetSearch}
            >
              Clear search
            </button>
          </div>}
          <div className="menu-actions__search-bar">
            <CuiSearchBar
              actions={actions}
              menuFilters={menuFilters}
              handlerFilterOnClick={handlerFilterOnClick}
              ref={searchInputRef}
            />
          </div>

          <div className="menu-actions__separator"></div>
        </>}

        <div className="menu-actions__buttons">
          <div className="menu-actions__buttons__choose-for-me">
            <CuiButton
              size="large"
              fill="outline"
              color="dark"
              onClick={onClickChooseForMe}
              disabled={
                totalOrderedProductsQty >= maxProductToCart ||
                isChooseForMeLoading
              }
            >
              <CuiIcon name="flashIcon" />
              <span className="only-desktop">{CHEFS_CHOICE_BUTTON_LABEL}</span>
            </CuiButton>
          </div>
        </div>
      </div>

      {preSelectError && (
        <div className="error-share">
          <img src={alert} alt="alert" />
          <p>{preSelectError}</p>
        </div>
      )}
      {showHighlightedMeals && (
        <HighlightedRow
          addProduct={addProduct}
          removeProduct={removeProduct}
          onMealClick={onMealClick}
          maxProductToCart={maxProductToCart}
          totalOrderedProductsQty={totalOrderedProductsQty}
          getCategory={getCategory}
          selectedProducts={getProductDataOfCart}
          meals={highlightedMeals.meals}
          getOrderQty={getOrderQty}
          actions={actions}
          title="Best meal recommendations for your first order"
          displayedMeals={meals}
        />
      )}

      <div className="menu-list-card-container container-cui">
        {mealsMenu && mealsMenu.match && mealsMenu.match.status ? (
          <>
            {meals &&
              meals.map(meal => (
                <MealCardFromApp
                  key={`mealCard-${meal.entity_id}`}
                  meal={{
                    ...meal,
                    premium_fee: meal.premium_fee,
                    protein_type: categoryList(meal),
                    full_path_meal_image: `${IMG_URL_SOURCE}${meal.image_path}${meal.image}`,
                    full_path_chef_image: `${IMG_URL_SOURCE}${meal.bannerpic}`,
                  }}
                  onAddItem={() => {
                    addProduct({
                      ...meal,
                    })
                  }}
                  onRemoveItem={() => {
                    removeProduct(meal)
                  }}
                  onMealClick={() => {
                    onMealClick({
                      entity_id: meal.entity_id,
                      stock: meal.stock,
                      quizRecommendation: meal.quizRecommendation || false,
                      position_in_menu: meal.position_in_menu || 0,
                      isQueryPublicEnabled,
                      inventoryId: meal.inventoryId,
                    })
                  }}
                  disableAddItem={totalOrderedProductsQty >= maxProductToCart}
                  quantity={getOrderQty(meal.entity_id)}
                  listName={`${meal.chef_firstname} ${meal.chef_lastname}`}
                  listType={'Meals list'}
                />
              ))}
          </>
        ) : (
          <div className="empty-state">
            <div className="empty-state__title">
              <h3>{mealsMenu && mealsMenu.match && mealsMenu.match.text}</h3>
            </div>
            {mealsMenu && mealsMenu.match && mealsMenu.match.displayResetCta &&
              <CuiButton
                size="large"
                fill="outline"
                color="dark"
                onClick={resetSearch}
              >
                <span>View all meals</span>
              </CuiButton>
            }
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  isChooseForMeLoading: getChooseForMeStatus(state),
  menuFilters: getMenuFilter(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: creatorCommons.trackingStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuList)
