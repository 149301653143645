import React from 'react'
import ComingSoonModal from './components/ComingSoonModal'

import './HomePageCui.scss'
import InputZipcodeCui from './components/InputZipcodeCui'
import imageHeader from '../../assets/img/headerImage.jpg'
import { CuiHeader } from 'front-lib'
import CommonQuestionsCui from '../../components/CommonQuestionsCui'
import { commonQuestionsHome } from 'src/constants'
import { openCustomerServiceChat } from 'src/utils/customerServiceChat'
import useTrackEvent from 'src/hooks/useTrackEvent'

const HomePage = ({
  isLoading,
  validZipCodeError,
  validZipCodeReset,
  continueAction,
  askEmail,
  initialZipcode,
}) => {
  const helpRequestedCallback = useTrackEvent({
    eventName: 'CTA Button Clicked',
  });

  const openComingSoonModal = !!validZipCodeError
  
  const handleHelpClick = () => {
    openCustomerServiceChat(helpRequestedCallback);
  };  

  return (
    <div className="containerHome-cui">
      <ComingSoonModal
        isOpen={openComingSoonModal}
        onClose={validZipCodeReset}
        isLoading={isLoading}
        onSumig={validZipCodeReset}
        email={validZipCodeError?.data.email}
      />

      <div className="homeHeaderContainer">
        <CuiHeader
          showNeedHelp
          showNeedHelpTitle
          showLogo
          homeLink={process.env.REACT_APP_SITE_BASE_URL}
          onClickHelp={handleHelpClick}
        />
        <div className="container-cui container-cui-row">
          <div className="container-text">
            <h1 className="rebrand">Sign Up</h1>
            <p className="rebrand">Get started with your zip code</p>
            <InputZipcodeCui
              continueAction={continueAction}
              isLoading={isLoading}
              askEmail={askEmail}
              initialZipcode={initialZipcode}
            />
          </div>
          <div className="half-container-image">
            <img src={imageHeader} alt="hero" />
          </div>
        </div>
      </div>

      <CommonQuestionsCui className="rebrand" options={commonQuestionsHome} />
    </div>
  )
}

export default HomePage
