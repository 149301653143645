import axios from 'axios'
import {
  signupData,
  mealPlans,
  sampleMenu,
  checkCoupon,
  getQuizData,
  signUpMenu,
  setSetting,
  ringByZipcode,
  menu,
  menuSortBy,
  getTaxRateFromZipCode,
  validateZipCode,
  menuWithAllergens,
  validateGiftcard,
} from './queries'
import * as menuListHelpers from 'src/redux/ducks/modules/MenuList/utils'
import gqlClient from '../gqlClient'

import * as sessionStorageUtil from 'src/utils/sessionStorage'

const getAccessToken = () => {
  return sessionStorageUtil.getAuthAccessToken()
}

const SIGNUP_AUTH0 = `${process.env.REACT_APP_SUBSCRIPTION_API_URL}auth0/signup`

async function signupClient(path, body) {
  return axios.post(`${SIGNUP_AUTH0}${path}`, body, {
    headers: {
      Authorization: getAccessToken(),
      'Content-type': 'application/json',
    },
  })
}

function addAddress(body) {
  return signupClient('/addAddress', body)
}

function setupIntents(body) {
  return signupClient('/setupIntents', body)
}

function addPaypal(body) {
  return signupClient('/addPaypal', body)
}

function createSignup(body) {
  return signupClient('/suscribe', body)
}

const gqlMap = {
  createSignup: (subscriptionCharge, user) =>
    createSignup({ user, subscriptionCharge }).then(res => res.data),
  publicAddPaypal: payload => addPaypal(payload).then(res => res.data),
  publicAddAddress: payload => addAddress(payload).then(res => res.data),
  setupIntents: payload => setupIntents(payload).then(res => res.data),
  getSignupData: ({ zipcode, couponCode }) =>
    gqlClient.publicQuery(signupData, { zipcode, couponCode }).then(res => {
      const timeslots = res.data.timeslots.filter(
        slot => !slot.label.includes('ASAP'),
      )

      return {
        ...res.data,
        timeslots,
      }
    }),
  getMealPlans: (zipCode, couponCode, onlyEconomicPlans = false) =>
    gqlClient
      .publicQuery(mealPlans, { zipCode, couponCode, onlyEconomicPlans })
      .then(res => res.data),
  getSampleMenu: () => {
    return gqlClient.publicQuery(sampleMenu, {}).then(res => {
      return res.data.sampleMenu
    })
  },
  applyCouponCode: coupon =>
    gqlClient.publicQuery(checkCoupon, { coupon }).then(res => res.data),
  getQuizData: () =>
    gqlClient.publicQuery(getQuizData, {}).then(res => {
      return {
        ...res.data,
      }
    }),
  getSignUpMenu: payload => {
    const {
      date,
      zipcode,
      tastes = [],
      ingredientsAvoid = [],
      goals = [],
      menuOrderByWeightExperiment,
    } = payload
    return gqlClient
      .publicQuery(signUpMenu, {
        date,
        zipcode,
        tastes,
        ingredientsAvoid,
        goals,
        menuOrderByWeightExperiment,
      })
      .then(res => {
        return res.data.signUpMenu
      })
  },
  getMenu: payload => {
    //TODO add diets and specificationsAvoid this when the backend is ready
    const {
      date,
      zipcode,
      useMealServiceAllergens,
      tastes = [],
      diets = [],
      specificationsAvoid = [],
      planSegmentId,
    } = payload

    const filters = {
      selectedTastes: tastes.map(t => t.id),
      selectedDiets: diets.map(d => ({ id: d.id, name: d.name })),
      selectedDietaryPreferences: specificationsAvoid.map(s => {
        return { id: s.id, name: s.name, label: s.label, inverse: !!s.inverse }
      }),
      planSegmentId,
    }

    return gqlClient
      .menuServiceQuery(useMealServiceAllergens ? menuWithAllergens : menu, {
        date,
        zipCode: zipcode,
        filters,
      })
      .then(res => {
        return menuListHelpers.transformMenuServiceResponse(res.data.menu.meals)
      })
  },
  getMenuSortBy: payload => {
    const { menuDate, sortBy, zipcode, planSegmentId } = payload

    return gqlClient
      .menuServiceQuery(menuSortBy, {
        date: menuDate,
        sortBy,
        zipCode: zipcode,
        filters: {
          planSegmentId,
        },
      })
      .then(res => {
        return menuListHelpers.transformMenuServiceResponse(res.data.menu.meals)
      })
  },
  setSetting: ({ key, value }) =>
    gqlClient.mutation(setSetting, { key, value }).then(res => res.data),
  getRingByZipcode: zipcode =>
    gqlClient.publicQuery(ringByZipcode, { zipcode }).then(res => res.data),
  getTaxRateFromZipCode: (zipcode = 0) =>
    gqlClient
      .publicQuery(getTaxRateFromZipCode, { zipcode })
      .then(res => res.data),
  validateZipCode: zipCode =>
    gqlClient.publicQuery(validateZipCode, { zipCode }).then(res => res.data),
  validateGiftcard: giftCardCode =>
    gqlClient
      .publicQuery(validateGiftcard, { giftCardCode })
      .then(res => res?.data?.response),
}

export default gqlMap
