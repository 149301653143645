import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import Modal from 'src/components/NewModal'

import './ComingSoonModal.scss'

function ComingSoonModal({ isOpen, onClose, isLoading, onSubmit, email }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} smallSize>
      <div className="comingSoonContainer">
        <h2>We haven't quite reached you yet.</h2>
        <p>
          But we're rapidly expanding! Give us your email and we'll let you know
          once we arrive in your area!
        </p>
        <br />

        <Formik
          enableReinitialize={true}
          initialValues={{
            emailValue: email || '',
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            emailValue: Yup.string()
              .email('Invalid email')
              .required('Required'),
          })}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return (
              <form>
                <div>
                  <input
                    type="text"
                    className={`form-control homeInputEmail ${
                      errors.emailValue && touched.emailValue
                        ? 'errorInput'
                        : ''
                    }`}
                    id="emailValue"
                    aria-describedby="emailValueHelp"
                    placeholder={'example@email.com'}
                    value={values.emailValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="">
                  <button
                    type="button"
                    className="sendHomeData"
                    onClick={handleSubmit}
                  >
                    {!isLoading ? (
                      'Send'
                    ) : (
                      <i className="spinner fas fa-circle-notch" />
                    )}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}

export default ComingSoonModal
