import { parse } from 'qs'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import * as actions from 'src/redux/ducks/modules/MenuList/actions'
import * as creators from 'src/redux/ducks/modules/MenuList/creators'

import { GQL } from 'src/api/services'

import * as MenuListSelectors from 'src/redux/ducks/modules/MenuList/selectors'
import * as menuListHelpers from 'src/redux/ducks/modules/MenuList/utils'

import * as commonCreators from 'src/redux/ducks/commons/creators'
import * as signUpCommonSelectors from 'src/redux/ducks/screens/signup/commons/selectors'

import { preSelectErrorMessage } from 'src/constants'
import * as mealStepCreators from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as mealStepSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as signUpUtils from 'src/redux/ducks/screens/signup/utils'
import * as config from '../../../../config/preselectedMeals'
import {
  checkAddPreselectedMealInArray,
  checkMissingItems,
  filterMealsByChefIdsParam,
  filterMealsByMealIdsParam,
  getPreselectedMeals,
  getNoPopulateCartFlag,
  getConfigFromNpc,
} from './utils/preselectProduct'
import { logError } from 'src/utils/logError'
import { FILTER_IDS } from 'src/modules/MenuListCui/Components/FiltersMenuBy/constants'
import { pageNames } from 'src/screens/signup/constants'

const sorryWeDontHaveMealsMessage =
  'Sorry, we don´t have meals in stock for this date. please go back and change your first delivery day.'
  
const sorryWeDontHaveMealsSearchMessage =
'We’re sorry, no items found'

const getApplicationState = () => select(state => state)

export function* loadMenuData({ payload }) {
  try {
    const applicationState = yield getApplicationState(),
      preferencesQuizResponses = signUpCommonSelectors.preferencesQuizResponses(
        applicationState,
      ),
      userSelectedData = signUpCommonSelectors.getUserSelectedData(
        applicationState,
      ),
      coupon = signUpCommonSelectors.getCoupon(applicationState),
      store = signUpCommonSelectors.getUserStore(applicationState),
      sharedMealId = signUpCommonSelectors.getSharedMealId(applicationState),
      { selectedPlan } = userSelectedData,
      showSampleMenu = payload.showSampleMenu || false,
      searchMealsIds = signUpCommonSelectors.getSearchMealsIds(
        applicationState,
      ),
      highlightedMealsIds = signUpCommonSelectors.getHighlightedMealsIds(
        applicationState,
      ),
      cartData = mealStepSelectors.getCart(applicationState)

    let displayedMealsIds = []

    let menuResponse = yield call(
      showSampleMenu ? GQL.getSampleMenu : GQL.getMenu,
      { 
        ...payload, 
        planSegmentId: selectedPlan?.segmentId 
      },
    )

    let productsInCart = payload.productsInCart || []
    let preselectedMeals = []
    let chefsImages = []
    let sharedMealError = ''

    const proteinAnswers = preferencesQuizResponses?.proteins
      ? preferencesQuizResponses.proteins
      : []
    const specificationsAvoidAnswers = preferencesQuizResponses?.specificationsAvoid
      ? preferencesQuizResponses.specificationsAvoid.filter(
          specification => specification.id !== -2,
        )
      : []
    const allergensAnswers = preferencesQuizResponses?.allergens
      ? preferencesQuizResponses.allergens.filter(
          specification => specification.id !== -2,
        )
      : []
    const dietAnswers = preferencesQuizResponses?.diets
      ? preferencesQuizResponses.diets.filter(diet => diet.id !== -2)
      : []

    const planMeals = selectedPlan ? selectedPlan.mealsPerDelivery : 0

    const arrayProperties = [
      'goals',
      'diets',
      'proteins',
      'ingredientsAvoid',
      'allergens',
      'specificationsAvoid',
    ]
    const hasArrayProperty = (obj, prop) =>
      Array.isArray(obj[prop]) && obj[prop].length
    const hasPreferences = arrayProperties.some(prop =>
      hasArrayProperty(preferencesQuizResponses, prop),
    )

    const { mealId: mealIdFromQueryParam } = parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    const preselectMealIds = sharedMealId ? [sharedMealId] : []

    const isPreselectMealCoupon =
      coupon && coupon.couponCode === config.PRESELECT_MEAL_COUPON

    if (!sharedMealId && isPreselectMealCoupon)
      config.PRESELECT_MEAL_IDS.map(id => preselectMealIds.push(id))

    if (!!Number(mealIdFromQueryParam))
      preselectMealIds.push(Number(mealIdFromQueryParam))

    if (Array.isArray(searchMealsIds) && searchMealsIds.length > 0) {
      try {
        searchMealsIds.map(id => preselectMealIds.push(Number(id)))
      } catch (error) {
        logError(error)
      }
    }

    let meals = []
    let newMeals = menuListHelpers.transformMealsWithDetails(
      menuResponse,
      proteinAnswers,
      dietAnswers,
      payload.useMealServiceAllergens,
      specificationsAvoidAnswers,
      allergensAnswers,
    )
    meals = newMeals.mealsMapped
    displayedMealsIds = newMeals.displayedMealsIds

    if (window.google_tag_manager) {
      let dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'displayedMealsIds',
        displayedMealsIds,
      })
    }

    let getPersonalizedMeals = meals || []

    getPersonalizedMeals = hasPreferences
      ? getPersonalizedMeals.slice(0, 20)
      : []

    const personalizedMeals = getPersonalizedMeals.map(meal => ({
      ...meal,
      quizRecommendation: true,
    }))

    if (preselectMealIds.length > 0) {
      preselectedMeals = checkAddPreselectedMealInArray(meals, preselectMealIds)
    }

    let highlightedMeals = []
    try {
      const stateMeals = checkAddPreselectedMealInArray(
        meals,
        highlightedMealsIds.map(id => Number(id)),
      ).map(m => m.data)

      const chefMeals = filterMealsByChefIdsParam(meals)
      const queryMeals = filterMealsByMealIdsParam(meals)

      highlightedMeals = [...stateMeals, ...queryMeals, ...chefMeals].filter(
        (meal, index, self) =>
          index === self.findIndex(m => m.entity_id === meal.entity_id),
      )

      const cartCount = signUpUtils.sumAllQty(cartData.products)

      if (highlightedMeals.length > 0 && cartCount < planMeals) {
        preselectedMeals = [
          ...preselectedMeals,
          ...getPreselectedMeals(highlightedMeals),
        ]
      }
    } catch (error) {
      logError(error, { location: 'loadMenuData' })
    }

    if(preselectedMeals.length > 0) {
      
      sharedMealError =
        sharedMealId && preselectedMeals.length < 1 ? preSelectErrorMessage : ''
      yield put(mealStepCreators.setPreselectError(sharedMealError))

      let missingItems = checkMissingItems(preselectedMeals, preselectMealIds)

      const trackingDescription = sharedMealId
        ? config.PRESELECT_SHARE
        : isPreselectMealCoupon
        ? config.PRESELECT_MEAL_COUPON
        : config.PRESELECT_QUERY_PARAM

      const countPreselectedMeals = Array.isArray(preselectedMeals)
        ? preselectedMeals.length
        : 0

      yield put(
        commonCreators.trackingStart({
          eventName: config.EVENT_NAME,
          eventData: {
            event_action: config.EVENT_NAME,
            promo_code_or_share_description: trackingDescription,
            page_name: 'Meals Selection',
            not_preselected_meals: missingItems,
            store_id: store.id,
            product_add_source: config.ADD_SOURCE,
            plan_size: planMeals,
            preselect_meals_ids:
              Array.isArray(preselectedMeals) &&
              preselectedMeals.map(m => m.id),
            preselected_meals_count: countPreselectedMeals,
          },
        }),
      )

      preselectedMeals = preselectedMeals.filter(
        m => !productsInCart.find(l => m.id === l.id),
      )
      productsInCart = [...preselectedMeals, ...productsInCart]
    }

    if (cartData && cartData.products && cartData.products.length > 0) {
      preselectedMeals = preselectedMeals.filter(
        m => !cartData.products.find(c => m.id === c.id),
      )
    }

    const noPopulateCart = getNoPopulateCartFlag() || false

    const keepingMealInCart = menuListHelpers
      .keepPreviousSelectionCart(meals, [
        ...(noPopulateCart ? [] : preselectedMeals),
        ...cartData.products,
      ])
      .slice(0, planMeals)

    yield put(mealStepCreators.resetCart())

    if (keepingMealInCart.length > 0) {
      productsInCart = keepingMealInCart.map(row => row.data)

      yield put(
        mealStepCreators.addCart({
          products: keepingMealInCart,
          totalProductQty: signUpUtils.sumAllQty(keepingMealInCart),
        }),
      )
    }

    yield put(
      commonCreators.loggingStart({
        event: 'menuLoaded',
        data: {
          mode: payload.mode,
        },
      }),
    )

    const menuMeals = hasPreferences ? [...meals.slice(planMeals)] : [...meals]
    let mergedMenuMeals = [
      ...new Map(
        [...personalizedMeals, ...menuMeals].map(m => [m.entity_id, m]),
      ).values(),
    ]

    const { sortMenu: sortMenuConfig } = getConfigFromNpc()
    if (sortMenuConfig && highlightedMeals.length > 0) {
      mergedMenuMeals = menuListHelpers.orderMealSelectedWithFirstPosition(
        highlightedMeals,
        meals,
      )
    }

    const selectedMealInFirstPosition = menuListHelpers.orderMealSelectedWithFirstPosition(
      productsInCart,
      mergedMenuMeals,
    )
    const selectedPersonalizedMealsInFirstPosition = menuListHelpers.orderMealSelectedWithFirstPosition(
      productsInCart,
      personalizedMeals,
    )

    const { highlightId } = parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    yield put(
      creators.loadProducts({
        menu: {
          highlight: {
            type: 'chef',
            id: +highlightId,
          },
          meals: selectedMealInFirstPosition,
          allMeals: mergedMenuMeals,
          match: {
            status: meals.length > 0,
            text: meals.length > 0 ? '' : sorryWeDontHaveMealsMessage,
          },
        },
        personalized: {
          chefsImages: chefsImages,
          meals: selectedPersonalizedMealsInFirstPosition,
          match: {
            status: personalizedMeals.length > 0,
            text:
              personalizedMeals.length > 0 ? '' : sorryWeDontHaveMealsMessage,
          },
        },
        highlightedMeals: {
          meals: highlightedMeals,
        },
        showRecommendationsFilter:
          meals.filter(meal => meal.matches_preferences).length >= 6,
      }),
    )
    yield put(creators.resetFilterBy())
  } catch (error) {
    logError(error)
    yield put(creators.loadProductsFailed(error))
  }
}

export function* filterMeals({ payload }) {
  const applicationState = yield getApplicationState()
  const currentMenuFilter = MenuListSelectors.getMenuFilter(applicationState)
  const productsInCart = payload.productsInCart
  const store = signUpCommonSelectors.getUserStore(applicationState)
  const userSelectedData = signUpCommonSelectors.getUserSelectedData(
    applicationState,
  )
  const { startDay } = userSelectedData

  let filterReset = false

  filterReset =
    payload.type && !payload.searchInput &&
    (currentMenuFilter.meatTypeFilter === payload.id ||
      currentMenuFilter.specificationsDetailFilter === payload.id)

  let mealsFilteredResult = []
  let filtersApplied = [{
    filter_group: 'All',
    filter_type: payload.type,
    filter_value: payload.id,
  }]

  if (filterReset) {
    const { allMeals } = payload,
    selectedMealInFirstPosition = menuListHelpers.orderMealSelectedWithFirstPosition(
      productsInCart,
      allMeals,
    )

    mealsFilteredResult = [...selectedMealInFirstPosition]

    yield put(
      creators.filterReset({
        meals: selectedMealInFirstPosition,
        match: {
          status: allMeals.length > 0,
          text: allMeals.length > 0 ? '' : sorryWeDontHaveMealsMessage,
        },
      }),
    )
  } else {
    const data = menuListHelpers.filter(payload)
    const filters = data && data.filters,
      filterType =
        filters.specificationsDetailFilter !== ''
          ? 'specificationsDetailFilter'
          : 'meatTypeFilter'
    mealsFilteredResult = data.meals
    const mealWithLessStock = mealsFilteredResult.filter(meal => meal.stock <= 3)

    yield put(
      commonCreators.loggingStart({
        event: 'applyFilter',
        data: {
          meals: mealWithLessStock.length > 0 ? mealWithLessStock : null,
          filterType,
          quantity: mealsFilteredResult.length,
        },
      }),
    )

    const isNoMeals = mealsFilteredResult.length === 0;
    const displayNoSearchResults = isNoMeals && payload.searchInput !== '';
    
    const statusText = getStatusText({ isNoMeals, displayNoSearchResults });

    yield put(
      creators.filter({
        meals: menuListHelpers.orderMealSelectedWithFirstPosition(
          productsInCart,
          mealsFilteredResult,
        ),
        match: {
          status: mealsFilteredResult.length > 0,
          text: statusText,
          displayResetCta: displayNoSearchResults,
        },
        filters,
      }),
    )
  }

  if (payload.searchInput) {
    filtersApplied.push({
      filter_group: '',
      filter_type: 'Search',
      filter_value: payload.searchInput,
    })
  }

  yield put(
    commonCreators.trackingStart({
      eventName: 'Sign Up - Meals Selection - Filters Used',
      eventData: {
        filter_id: payload.id,
        filter_name: payload.id,
        filter_type: payload.type,
        filter_value: payload.searchInput,
        action:
          payload.currentFilter !== payload.id || payload.searchInput ? 'add_filter' : 'remove_filter',
      },
    }),
  )

  yield put(
    commonCreators.trackingStart({
      eventName: 'Sign Up - Product List Filtered',
      eventData: {
        list_category: 'Meals',
        list_name: 'Meals',
        list_type: 'Regular',
        menu_store: store.id,
        delivery_date: startDay.displayDate,
        count_products_available_before_filter: payload.meals.filter(
          item => item.stock > 0,
        ).length,
        count_products_available_after_filter: mealsFilteredResult.filter(
          item => item.stock > 0,
        ).length,
        count_products_displayed_before_filter: payload.meals.length,
        count_products_displayed_after_filter: mealsFilteredResult.length,
        filters_applied: filtersApplied,
      },
    }),
  )
}

const getStatusText = ({ isNoMeals, displayNoSearchResults }) => {
  if (isNoMeals) {
    if (displayNoSearchResults) {
      return sorryWeDontHaveMealsSearchMessage;
    } else {
      return sorryWeDontHaveMealsMessage;
    }
  }
  return '';
}

export function* filterBy({ payload }) {
  const applicationState = yield getApplicationState()
  const cartData = mealStepSelectors.getCart(applicationState)
  const menuData = MenuListSelectors.getMenuData(applicationState)
  const store = signUpCommonSelectors.getUserStore(applicationState)
  const userSelectedData = signUpCommonSelectors.getUserSelectedData(
    applicationState,
  )
  const { startDay } = userSelectedData

  try {
    const { menu, tab, isDefault } = payload
    const allMeals = menuData.menu.allMeals
    const pastFilteredMeals = menuData.menu.meals

    if (!Array.isArray(allMeals)) return

    let filteredMeals = allMeals.filter(meal => {
      if (menu.id === FILTER_IDS.CHEF) {
        return meal.chef_id === tab.id
      }
      if (menu.id === FILTER_IDS.RECOMMENDED) {
        return !!meal.matches_preferences
      }
      if (menu.id === FILTER_IDS.DIET) {
        return meal.filter_by && meal.filter_by.includes(tab.pattern)
      }
      if (menu.id === FILTER_IDS.PREMIUM) {
        return !!meal.premium_fee
      }
      if (menu.id === FILTER_IDS.PROTEIN) {
        return tab.types.includes(meal.meat_type)
      }
      if (menu.id === FILTER_IDS.CUISINE) {
        return meal.cuisines.includes(tab.pattern)
      }
      return true
    })

    filteredMeals = menuListHelpers.orderMealSelectedWithFirstPosition(
      cartData.products,
      filteredMeals,
    )

    yield put(
      creators.filter({
        meals: filteredMeals,
        match: {
          status: filteredMeals.length > 0,
          text: filteredMeals.length > 0 ? '' : sorryWeDontHaveMealsMessage,
        },
        filters: {
          filterBy: { menu, tab },
        },
      }),
    )

    yield put(
      commonCreators.trackingStart({
        eventName: 'Sign Up - Product List Filtered',
        eventData: {
          page_name: pageNames.MEALS,
          menu_store: store.id,
          delivery_date: startDay.displayDate,
          list_category: 'Meals',
          list_name: 'Meals',
          list_type: 'Regular',
          list_menu_by: `Show Menu By ${menu.name}`,
          filter_group: payload.menu.name,
          filter_value: payload.tab.name,
          filter_type: 'quick',
          count_products_available_before_filter: pastFilteredMeals.filter(
            item => item.stock > 0,
          ).length,
          count_products_available_after_filter: filteredMeals.filter(
            item => item.stock > 0,
          ).length,
          count_products_displayed_before_filter: pastFilteredMeals.length,
          count_products_displayed_after_filter: filteredMeals.length,
          filter_default_menu_by: isDefault,
        },
      }),
    )
  } catch (error) {
    console.error(error)
  }
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(actions.LOAD_PRODUCT.start, loadMenuData),
    takeEvery(actions.LOAD_PRODUCT.startFilter, filterMeals),
    takeEvery(actions.LOAD_PRODUCT.startFilterBy, filterBy),
  ])
}
