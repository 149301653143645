import { NPC_CONFIG } from "src/constants/gem"
import { logError } from "../../../../../utils/logError"
import {
  getChefIdsParam,
  getMealIdsParam,
  getNpcParam,
} from '../../../../../utils/queryCookie'

/**
 * @description Create a new array of meals passing an array of meals and a list of ids.
 * @param array {Array<MealTypeObject>}
 * @param mealIds {Array<Int>}
 * @returns {Array<MealTypeObject>}
 */
export const checkAddPreselectedMealInArray = (array, mealIds) => {
  let newArray = []
  for (var i = 0; i < array.length; i++) {
    if (mealIds.indexOf(array[i].entity_id) >= 0 && array[i].stock > 0) {
      let preselectedMeal = { id: array[i].entity_id, qty: 1, data: array[i] }
      newArray.push(preselectedMeal)
    }
  }
  return newArray
}

/**
 * @description Create a new array of ids representing the missing meals in stock.
 * @param preselectedMeals {Array<MealTypeObject>}
 * @param mealIds {Array<Int>}
 * @returns {Array<Int>}
 */
export const checkMissingItems = (preselectedMeals, mealIds) => {
  let missingIds = mealIds;
  for (let i = 0; i < preselectedMeals.length; i++){
    if(missingIds.indexOf(preselectedMeals[i].id) > -1){
      missingIds.splice(missingIds.indexOf(preselectedMeals[i].id), 1)
    }
  }
  return missingIds;
}

export const filterMealsByChefIdsParam = meals => {
  try {
    const storedChefIds = getChefIdsParam()
    const chefIds = storedChefIds?.split(',').map(id => parseInt(id, 10))
    if (chefIds?.length > 0) {
      return meals
        .filter(meal => chefIds.includes(meal.chef_id))
        .filter(meal => !meal.premium_fee)
        .sort((a, b) => {
          return chefIds.indexOf(a?.chef_id) - chefIds.indexOf(b?.chef_id)
        })
    }
  } catch (error) {
    logError(error, { location: 'filterMealsByChefIdsParam' })
  }
  return []
}

export const filterMealsByMealIdsParam = meals => {
  try {
    const storedMealIds = getMealIdsParam()
    const mealIds = storedMealIds?.split(',').map(id => parseInt(id, 10))
    if (mealIds?.length > 0) {
      return meals
        .filter(meal => mealIds.includes(meal.entity_id))
        .filter(meal => !meal.premium_fee)
    }
  } catch (error) {
    logError(error, { location: 'filterMealsByMealIdsParam' })
  }
  return []
}

export const getPreselectedMeals = meals => {
  try {
    if (meals.length > 0) {
      const recommendedMeals = meals.filter(
        meal => meal.matches_preferences,
      )
      if (recommendedMeals.length > 0) {
        return recommendedMeals.map(meal => {
          return { id: meal.entity_id, qty: 1, data: meal }
        })
      }
      return meals.map(meal => {
        return { id: meal.entity_id, qty: 1, data: meal }
      })
    }
  } catch (error) {
    logError(error, { location: 'getPreselectedMeals' })
  }
  return []
}

export const getConfigFromNpc = () => {
  const config = NPC_CONFIG
  try {
    const value = getNpcParam()
    return config[value] || config['0']
  } catch (error) {
    logError(error, { location: 'getConfigFromNpc' })
    return config['0']
  }
}

export const getNoPopulateCartFlag = () => {
  try {
    const { noPopulateCart } = getConfigFromNpc()
    return !!noPopulateCart
  } catch (error) {
    logError(error, { location: 'getNoPopulateCartFlag' })
  }
  return false
}
