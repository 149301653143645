import React, { forwardRef, useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import './Slider.scss'
import "swiper/scss/pagination";
import { Pagination } from 'swiper'

const Slider = ({ children, configs }, ref) => {
  const [swiperRef, setSwiperRef] = useState()

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slidePrev()
  }, [swiperRef])

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return
    swiperRef.slideNext()
  }, [swiperRef])

  return (
    <div ref={ref} className="swiper-container">
      <button className="swiper-button-prev" onClick={handleLeftClick} />
      <button className="swiper-button-next" onClick={handleRightClick} />
      <Swiper
        onSwiper={setSwiperRef}
        {...configs}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
      >
        {React.Children.map(
          children,
          child => child && <SwiperSlide>{child}</SwiperSlide>,
        )}
      </Swiper>
    </div>
  )
}

export default forwardRef(Slider)
