import React, { useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import alert from 'src/assets/icons/alert.png'
import { bindActionCreators } from 'redux'
import Loader from 'src/components/Loader'
import * as creatorCommons from 'src/redux/ducks/commons/creators'
import MealCardFromApp from '../../components/MealCardPremiumFee'

import './MenuListCategories.scss'
import {
  filtersOptions,
  forYouFilterOption,
  getSortedTopRatedMeals,
} from './utils'
import HighlightedRow from './Components/HighlightedRow'

import { getChooseForMeStatus } from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import { getMenuFilter } from 'src/redux/ducks/modules/MenuList/selectors'
import { FILTER_IDS } from './Components/FiltersMenuBy/constants'
import { CuiButton, TabsMenu, isMobileOrTablet } from 'front-lib'
import classNames from 'classnames'
import { getSignUpInitData } from 'src/redux/ducks/screens/signup/commons/selectors'
import { capitalizeFirstLetter } from 'src/utils/stringUtils'
import { ForYou } from './Components/ForYou'
import { authUserSelector } from 'src/features/auth/authSlice'
import { useSelector } from 'react-redux'
import QuizPreferencesSelected from './Components/QuizPreferencesSelected'
import ChooseForMe from './Components/ChooseForMe'
import { useFlag } from '@unleash/proxy-client-react'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'
import CuiSearchBar from 'src/components/CuiSearchBar/CuiSearchBar'
import { MENU_OPTIONS } from './contants'
import { getConfigFromNpc } from 'src/redux/ducks/modules/MenuList/utils/preselectProduct'

const MenuListCategories = props => {
  const {
    isLoading,
    mealsMenu,
    addProduct,
    removeProduct,
    categoryList,
    onMealClick,
    handlerFilterOnClick,
    meatTypeFilter,
    specificationsDetailFilter,
    countOrderProduct,
    maxProductToCart,
    totalOrderedProductsQty,
    preSelectError,
    setShowQuiz,
    getCategory,
    getProductDataOfCart,
    actions,
    onClickChooseForMe,
    isChooseForMeLoading,
    highlightedMeals,
    menuFilters,
    preferencesQuizResponses,
    selectedMenuOption,
    viewAll,
    onClickViewAll,
    preferencesSettled,
    storeData,
    bestSellerMeals,
    onSelectMenuOption,
    onClickViewFullMenu,
  } = props

  const isQueryPublicEnabled = useFlag(
    'gro-gtt-4387-enable-meal-public-query-funnel',
  )

  const { isTreatment: mealSearchBarExperiment } = useExperiment(
    EXPERIMENTS.mealSearchBar,
    {
      trackOnMount: true,
    },
  )

  const searchInputRef = useRef()

  const resetSearch = () => {
    searchInputRef.current?.resetInput()
    const { meatTypeFilter, specificationsDetailFilter } = menuFilters
    const id = meatTypeFilter || specificationsDetailFilter
    const type = meatTypeFilter ? 'meatType' : 'specificationsDetail'
    handlerFilterOnClick(id, type, undefined, false, '')
  }

  const displayClearSearch = useMemo(
    () => isMobileOrTablet() && menuFilters.searchInput,
    [menuFilters.searchInput],
  )

  const authUser = useSelector(authUserSelector)

  const IMG_URL_SOURCE = process.env.REACT_APP_IMG_URL_SOURCE
  const _filtersOptions = [forYouFilterOption, ...filtersOptions]

  const getOrderQty = id =>
    typeof countOrderProduct === 'function' ? countOrderProduct(id) : 0

  const { allMeals, meals } = mealsMenu

  const menuSelected = useMemo(() => menuFilters?.filterBy?.menu || {}, [
    menuFilters,
  ])

  const showHighlightedMeals = useMemo(() => {
    const { displayHighlightedRow } = getConfigFromNpc()

    if (displayHighlightedRow === 'never') {
      return false
    }

    if (displayHighlightedRow === 'always') {
      return (
        highlightedMeals &&
        Array.isArray(highlightedMeals.meals) &&
        highlightedMeals.meals.length > 0
      )
    }

    return (
      (meatTypeFilter === 'all' || menuSelected.id === FILTER_IDS.ALL) &&
      highlightedMeals &&
      Array.isArray(highlightedMeals.meals) &&
      highlightedMeals.meals.length > 0 &&
      selectedMenuOption === MENU_OPTIONS.FULL_MENU
    )
  }, [highlightedMeals, meatTypeFilter, menuSelected.id, selectedMenuOption])

  if (isLoading) {
    return <Loader />
  }

  const recommendedMeals = meals.filter(meal => meal.matches_preferences)
  const mealsToRecommend =
    recommendedMeals.length === 0 ? meals : recommendedMeals
  const topRatedMeals = getSortedTopRatedMeals(allMeals)

  let mealsToShow
  if (selectedMenuOption === MENU_OPTIONS.FOR_YOU) {
    if (viewAll === 'recommended') {
      mealsToShow = mealsToRecommend
    } else if (viewAll === 'bestSellers') {
      mealsToShow = bestSellerMeals.slice(0, 20)
    } else if (viewAll === 'topRated') {
      mealsToShow = topRatedMeals
    }
  } else {
    mealsToShow = meals
  }

  let dynamicText
  if (viewAll) {
    if (viewAll === 'recommended') {
      dynamicText = 'BASED ON YOUR PREFERENCES'
    } else {
      dynamicText = ''
    }
  } else if (selectedMenuOption === MENU_OPTIONS.FOR_YOU) {
    dynamicText = `Welcome ${authUser?.given_name}! 👋`
  } else {
    dynamicText = ''
  }

  let dynamicTitle
  if (viewAll) {
    if (viewAll === 'recommended') {
      dynamicTitle = `${isMobileOrTablet() ? 'Here are' : "We've selected"} ${
        mealsToRecommend.length
      } delicious meals you will love`
    } else if (viewAll === 'bestSellers') {
      dynamicTitle = (
        <>
          Our top 20 best sellers in{' '}
          <span className="for-you__title--orange">{storeData.name}</span>
        </>
      )
    } else if (viewAll === 'topRated') {
      dynamicTitle = 'Must-try meals from our amazing chefs 👩‍🍳'
    }
  } else {
    dynamicTitle = `${capitalizeFirstLetter(
      meatTypeFilter || specificationsDetailFilter,
    )} meals available on your delivery date`
  }

  const selectedTab =
    selectedMenuOption === MENU_OPTIONS.FOR_YOU
      ? MENU_OPTIONS.FOR_YOU
      : meatTypeFilter || specificationsDetailFilter

  return (
    <div
      className={`menu-list-exp-container new-meal-card-design menu-list-no-modal`}
    >
      {!viewAll && (
        <div className="cui-menu-filter container-cui">
          <TabsMenu
            showNavigation={true}
            handleOnClick={({ id, type }) => {
              if (type === 'menu') {
                onSelectMenuOption(id)
              } else {
                onSelectMenuOption(MENU_OPTIONS.FULL_MENU)
              }
              handlerFilterOnClick(id, type)
            }}
            tabsItems={_filtersOptions}
            selectedTab={{ id: selectedTab }}
          />
        </div>
      )}

      {showHighlightedMeals && (
        <HighlightedRow
          addProduct={addProduct}
          removeProduct={removeProduct}
          onMealClick={onMealClick}
          maxProductToCart={maxProductToCart}
          totalOrderedProductsQty={totalOrderedProductsQty}
          getCategory={getCategory}
          selectedProducts={getProductDataOfCart}
          meals={highlightedMeals.meals}
          getOrderQty={getOrderQty}
          actions={actions}
          title="Best meal recommendations for your first order"
          displayedMeals={meals}
        />
      )}

      {(selectedMenuOption !== MENU_OPTIONS.FOR_YOU || viewAll) && (
        <div className="container-cui">
          <div className="cui-dynamic-title">
            {dynamicText && <p className="pre-title">{dynamicText}</p>}
            <div className="menu-title-container">
              <h2 className="title">{dynamicTitle}</h2>
              {!viewAll && (
                <div className="title-actions">
                  {mealSearchBarExperiment && (
                    <>
                      <div className="menu-actions__search-bar menu-actions__search-bar--for-you">
                        <CuiSearchBar
                          actions={actions}
                          menuFilters={menuFilters}
                          handlerFilterOnClick={handlerFilterOnClick}
                          ref={searchInputRef}
                          placeholder={'Search your favorite meals...'}
                        />
                      </div>
                      <div class="vertical-line"></div>
                    </>
                  )}
                  <ChooseForMe
                    onClickChooseForMe={onClickChooseForMe}
                    totalOrderedProductsQty={totalOrderedProductsQty}
                    maxProductToCart={maxProductToCart}
                    isChooseForMeLoading={isChooseForMeLoading}
                    size={isMobileOrTablet()? 'medium':'large'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {viewAll === 'recommended' && (
        <div className="container-cui for-you__preferences">
          <QuizPreferencesSelected
            preferencesToShow={preferencesQuizResponses}
            setShowQuiz={setShowQuiz}
          />
          <div className="choose-for-me--margin">
            <ChooseForMe
              onClickChooseForMe={onClickChooseForMe}
              totalOrderedProductsQty={totalOrderedProductsQty}
              maxProductToCart={maxProductToCart}
              isChooseForMeLoading={isChooseForMeLoading}
            />
          </div>
        </div>
      )}

      {selectedMenuOption === MENU_OPTIONS.FULL_MENU && (
        <div className="menu-actions-exp container-cui">
          <span className="menu-actions-exp--text">
            {mealSearchBarExperiment && menuFilters.searchInput
              ? `Showing ${meals.length} meals for ${menuFilters.searchInput}:`
              : `Showing ${meals.length} meals:`}
          </span>
          {displayClearSearch && (
            <div className="menu-actions__clear-search menu-actions__clear-search--for-you">
              <button onClick={resetSearch}>Clear search</button>
            </div>
          )}
        </div>
      )}

      {preSelectError && (
        <div className="error-share">
          <img src={alert} alt="alert" />
          <p>{preSelectError}</p>
        </div>
      )}
      <div
        className={classNames('container-cui', {
          'menu-list-exp-card-container':
            selectedMenuOption !== MENU_OPTIONS.FOR_YOU || viewAll,
          'view-all-section': viewAll,
        })}
      >
        {selectedMenuOption === MENU_OPTIONS.FOR_YOU && !viewAll ? (
          <ForYou
            addProduct={addProduct}
            removeProduct={removeProduct}
            onMealClick={onMealClick}
            maxProductToCart={maxProductToCart}
            totalOrderedProductsQty={totalOrderedProductsQty}
            setShowQuiz={setShowQuiz}
            getCategory={getCategory}
            getProductDataOfCart={getProductDataOfCart}
            onClickChooseForMe={onClickChooseForMe}
            isChooseForMeLoading={isChooseForMeLoading}
            preferencesQuizResponses={preferencesQuizResponses}
            onClickViewAll={onClickViewAll}
            preferencesSettled={preferencesSettled}
            storeName={storeData.name}
            bestSellerMeals={bestSellerMeals}
            getOrderQty={getOrderQty}
            recommendedMeals={mealsToRecommend}
            topRatedMeals={topRatedMeals}
            onClickViewFullMenu={onClickViewFullMenu}
            allMealsCount={allMeals.length}
          />
        ) : mealsMenu?.match?.status ? (
          mealsToShow?.map((meal, index) => (
            <MealCardFromApp
              index={viewAll === 'bestSellers' && index}
              key={`mealCard-${meal.entity_id}`}
              meal={{
                ...meal,
                premium_fee: meal.premium_fee,
                protein_type: categoryList(meal),
                full_path_meal_image: `${IMG_URL_SOURCE}${meal.image_path}${meal.image}`,
                full_path_chef_image: `${IMG_URL_SOURCE}${meal.bannerpic}`,
              }}
              onAddItem={() => {
                addProduct({
                  ...meal,
                })
              }}
              onRemoveItem={() => {
                removeProduct(meal)
              }}
              onMealClick={() => {
                onMealClick({
                  entity_id: meal.entity_id,
                  stock: meal.stock,
                  quizRecommendation: meal.quizRecommendation || false,
                  position_in_menu: meal.position_in_menu || 0,
                  isQueryPublicEnabled,
                  inventoryId: meal.inventoryId,
                })
              }}
              disableAddItem={totalOrderedProductsQty >= maxProductToCart}
              quantity={getOrderQty(meal.entity_id)}
              listName={`${meal.chef_firstname} ${meal.chef_lastname}`}
              listType={'Meals list'}
              selectedMenuOption={selectedMenuOption}
            />
          ))
        ) : (
          <div className="empty-state">
            <div className="empty-state__title">
              <h3>{mealsMenu && mealsMenu.match && mealsMenu.match.text}</h3>
            </div>
            {mealsMenu && mealsMenu.match && mealsMenu.match.displayResetCta && (
              <CuiButton
                size="large"
                fill="outline"
                color="dark"
                onClick={resetSearch}
              >
                <span>View all meals</span>
              </CuiButton>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  isChooseForMeLoading: getChooseForMeStatus(state),
  menuFilters: getMenuFilter(state),
  storeData: getSignUpInitData(state).store,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendTracking: creatorCommons.trackingStart,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuListCategories)
