import {
  put,
  call,
  takeEvery,
  all,
  select,
  spawn,
  takeLatest,
} from 'redux-saga/effects'
import { GQL } from 'src/api/services'
import * as api from 'src/api'
import * as signUpCommonActionCreator from './creators'
import * as signUpCommonSelectors from './selectors'
import * as signUpCommonActions from './actions'
import * as checkoutPageSelectors from 'src/redux/ducks/screens/signup/pages/Checkout/selectors'
import * as preOrderPageSelectors from 'src/redux/ducks/screens/signup/pages/MealsStep/selectors'
import * as checkoutPageActionCreator from 'src/redux/ducks/screens/signup/pages/Checkout/creators'
import * as mealsStepActionCreator from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as preferencesQuizActionCreator from './creators'
import * as commonCreators from 'src/redux/ducks/commons/creators'
import * as signUpCommonCreators from 'src/redux/ducks/screens/signup/commons/creators'
import * as mealStepPageCreators from 'src/redux/ducks/screens/signup/pages/MealsStep/creators'
import * as DeliveryPageCreators from 'src/redux/ducks/screens/signup/pages/DeliverySettings/creators'
import * as handleQuizPreferences from 'src/redux/ducks/modules/MenuList/utils/handleQuizPreferences'
import { getCouponApplicationEventData } from '../utils'
import { getUtmSource } from '../../../../../utils/utils'
import { getCUCookie } from '../../../../../utils/cookieUtils'
import {
  isExpiredCoupon,
  hasRestrictions,
  isEligibleCoupon,
  transformCoupon,
  isEligibleZeroInvoices,
  getCouponsPerPlanByCouponCode,
} from '../../../../../utils/coupons'
import {
  getAllActives,
  composeObjectWithVariant,
} from '../../../../../utils/experiments'
import { filterReferralUpcomingsDays } from '../../../../../utils/referrals'
import { persistState as persistStateUtil } from '../../../../../utils/sessionStorage'
import { DEFAULT_COUPON } from 'src/constants'
import { eventNames } from '../../../../../config/tracking'
import * as chefsListUtils from '../utils/chefsListUtils'
import CouponError from '../utils/CouponError'

import { identify } from 'src/redux/ducks/commons/saga'
import { logError, logInfo } from 'src/utils/logError'
import { EVENTS } from 'src/utils/events'
import { formatDeliveryDay } from 'src/utils/date'

const getApplicationState = () => select(state => state)

export function* initSignUpData({ payload }) {
  try {
    const {
      zipcode,
      coupon: couponCode,
      userCoupon,
      utm,
      email,
      diet,
      sharedMealId,
      planSize,
      searchMealsIds,
      onlyEconomicPlans,
    } = payload

    const promotionDisabled = onlyEconomicPlans

    const coupon = yield applyCoupon({
      payload: couponCode,
      promotionDisabled,
      userCoupon,
    })

    var checkGTM = setInterval(() => {
      if (window.google_tag_manager) {
        clearInterval(checkGTM)
        let dataLayer = window.dataLayer || []
        dataLayer.push({
          event: 'initSignup',
          UserEmail: email,
          UserCoupon: couponCode,
          UserZipcode: zipcode,
        })
      }
    }, 10)

    if (email) {
      yield spawn(api.saveEmailAndZip, zipcode, email)
    }

    try {
      const taxRate = yield call(GQL.getTaxRateFromZipCode, Number(zipcode))
      yield put(
        signUpCommonActionCreator.setTaxRateFromZipCode(taxRate.taxesAmount),
      )
    } catch (error) {
      logError(error, { message: 'error on initSignUpData function: taxRate' })
    }

    let signUpData
    try {
      signUpData = yield call(GQL.getSignupData, {
        zipcode,
        couponCode: coupon.code,
      })
    } catch (error) {
      logError(error, {
        message: 'error on initSignUpData function: signUpData',
      })
    }

    let mealPlansData
    try {
      mealPlansData = yield call(
        GQL.getMealPlans,
        zipcode,
        couponCode || '',
        onlyEconomicPlans,
      )

      const plansCoupon = getCouponsPerPlanByCouponCode({
        couponCode,
        storeId: signUpData.ring.store_id,
      })

      mealPlansData.plans = mealPlansData.plans.map(plan => ({
        ...plan,
        couponCode: plansCoupon && plansCoupon[plan.mealsPerDelivery],
        isEconomic: onlyEconomicPlans,
      }))
    } catch (error) {
      logError(error, {
        message: 'error on initSignUpData function: mealPlansData',
      })
    }

    const deliveryDaysData = signUpData?.deliveryDays ?? []
    if (!signUpData?.deliveryDays) {
      const message = 'initSignUpData: deliveryDays not in signUpData'
      logInfo(message, { message, payload: signUpData })
    }

    const userData = {
      store: signUpData.ring.store_id,
      ring: signUpData.ring.id,
      utmSource: getUtmSource(utm),
    }
    yield put(signUpCommonActionCreator.experimentStart(userData))

    yield put(checkoutPageActionCreator.setFormData({ email }))

    let initialUpcomingDays = signUpData.upcomingDays
      ? signUpData.upcomingDays
          .filter(day => day.available)
          .map(day => {
            const date = formatDeliveryDay(day)
            return date
          })
      : []

    const july2 = '2024-07-02'
    const july3 = '2024-07-03'
    const july4 = '2024-07-04'
    const july5 = '2024-07-05'
    const july6 = '2024-07-06'

    const unavailableDays = {
      3: [july4, july5],
      6: [july4, july5],
      10: [july4, july5],
      59: [july4],
      70: [july4, july5],
      93: [july3, july4],
      5: [july4, july5],
      27: [july4, july5],
      34: [july4, july5],
      62: [july4, july5],
      63: [july4, july5],
      105: [july2, july4],
      44: [july4, july5],
      48: [july4, july5],
      55: [july4, july5],
      11: [july4, july5],
      12: [july4, july5, july6],
      19: [july4, july5],
      28: [july4, july5],
      35: [july4, july5],
      45: [july4, july5],
      49: [july3, july4, july5],
    }

    const unavailableDaysForStore = unavailableDays[signUpData.ring.id]
    if (unavailableDaysForStore && unavailableDaysForStore.length > 0) {
      initialUpcomingDays = initialUpcomingDays.filter(initialDay => {
        return !unavailableDaysForStore.includes(initialDay.displayDate)
      })
    }

    const zipcodeStore = signUpData.ring.store_id

    const notAvailableDays = coupon?.restrictions?.notAvailableDays || undefined

    const upcomingDays = filterReferralUpcomingsDays(
      zipcodeStore,
      initialUpcomingDays,
      notAvailableDays,
    )

    let selectedDate = upcomingDays[0]

    const restrictedPlans = coupon?.restrictions?.notAvailablePlans || []

    yield put(
      signUpCommonActionCreator.initDataSuccess({
        ...signUpData,
        zipcode,
        ring: {
          ...signUpData.ring,
          isLocal: signUpData.ring.is_local,
          storeId: signUpData.ring.store_id,
        },
        store: signUpData.stores.find(store => store.id === zipcodeStore),
        mealPlans: mealPlansData.plans
          .filter(plan => restrictedPlans.indexOf(plan.id) < 0)
          .reverse(),

        deliveryDays: [...deliveryDaysData.deliveryDays],
        upcomingDays,
        selectedPlan: '',
        timeslot: {
          start: signUpData.timeslots[0].start,
          end: signUpData.timeslots[0].end,
          label: signUpData.timeslots[0].label,
        },
        days: selectedDate ? [selectedDate.day] : [],
        startDay: selectedDate || {},
        email: email,
        sharedMealId,
        onlyEconomicPlans,
      }),
    )

    yield put(
      signUpCommonActionCreator.setUserSelectedData({
        zipcode,
        utm,
        diet,
      }),
    )

    if (planSize) {
      const selectedPlan = mealPlansData?.plans?.find(
        plan => plan?.mealsPerDelivery?.toString() === planSize,
      )
      if (selectedPlan) {
        yield put(
          signUpCommonActionCreator.setUserSelectedData({
            selectedPlan,
          }),
        )
      }
    }

    if (searchMealsIds) {
      yield put(
        signUpCommonActionCreator.setUserSelectedData({
          searchMealsIds,
          highlightedMealsIds: searchMealsIds,
        }),
      )
    }

    yield identify()
  } catch (error) {
    logError(error, { message: 'error on initSignUpData function' })

    //@TODO hotfix temporal pero ahi que pensar que el back te devuelve errores con status 200
    yield put(signUpCommonActionCreator.initDataFailed(error))
  }
  yield put(signUpCommonActionCreator.setForceLoading(false))
}

export function* applyCoupon({ payload, promotionDisabled, userCoupon }) {
  const couponOrCookieCoupon =
    payload || getCUCookie('discountCode') || DEFAULT_COUPON
  let couponToSend = null

  try {
    const applicationState = yield getApplicationState()
    const { selectedPlan } = signUpCommonSelectors.getUserSelectedData(
      applicationState,
    )

    const { onlyEconomicPlans } = signUpCommonSelectors.getSignUpInitData(
      applicationState,
    )

    if (promotionDisabled || onlyEconomicPlans) {
      throw new Error('Promotions Disabled')
    }

    const couponCode = selectedPlan?.couponCode || couponOrCookieCoupon

    const couponData = yield call(GQL.applyCouponCode, couponCode)

    const couponErrorPayload = { couponCode, couponData }

    if (!couponData?.coupon) {
      throw new CouponError('applyCoupon: invalid coupon', couponErrorPayload)
    }

    const { coupon } = couponData

    couponToSend = coupon

    if (isExpiredCoupon(coupon)) {
      throw new CouponError('applyCoupon: expired coupon', couponErrorPayload)
    }

    if (hasRestrictions(coupon, selectedPlan?.id)) {
      throw new CouponError(
        'applyCoupon: coupon with restrictions',
        couponErrorPayload,
      )
    }

    if (!isEligibleCoupon(coupon)) {
      throw new CouponError(
        'applyCoupon: coupon non eligible',
        couponErrorPayload,
      )
    }

    if (isEligibleZeroInvoices(coupon)) {
      throw new CouponError(
        'applyCoupon: coupon is eligible zero invoices',
        couponErrorPayload,
      )
    }

    yield put(
      commonCreators.trackingStart({
        eventName: eventNames.appliedCoupon.SUCCESS,
        eventData: getCouponApplicationEventData(coupon),
      }),
    )
    yield put(
      signUpCommonActionCreator.applyCouponSuccess({
        ...coupon,
        couponCode: couponOrCookieCoupon,
        hiddenCouponCode: couponCode,
        userCoupon: userCoupon || couponOrCookieCoupon,
      }),
    )

    logInfo('applyCoupon: coupon applied successfully', couponErrorPayload)
  } catch (error) {
    logError(error.message, error.payload)
    const couponErrorMessage = 'Invalid Coupon'
    yield put(
      commonCreators.loggingStart({
        event: 'couponError',
        warning: true,
        data: {
          error: couponErrorMessage,
          errorData: error,
        },
      }),
    )
    yield put(
      commonCreators.trackingStart({
        eventName: eventNames.appliedCoupon.FAILURE,
        eventData: {
          error: couponErrorMessage,
          errorData: error,
          ...getCouponApplicationEventData(couponToSend),
        },
      }),
    )
    yield put(
      signUpCommonActionCreator.applyCouponSuccess({
        couponCode: couponOrCookieCoupon,
      }),
    )
    yield put(signUpCommonActionCreator.applyCouponFailed(couponErrorMessage))
  }

  return { ...couponToSend, code: couponOrCookieCoupon }
}

export function* setUserData({ payload }) {
  yield put(signUpCommonActionCreator.setUserSelectedData(payload))
}

export function* fetchBreadPath({ payload }) {
  if (!payload) return

  const applicationState = yield getApplicationState(),
    breadPathState = signUpCommonSelectors.getBreadPathData(applicationState)

  const currentBreadPath = breadPathState.find(item => item.step === payload)

  if (!currentBreadPath) return

  const currentBreadPathIndex = breadPathState.findIndex(
    item => item.step === payload,
  )
  const afterPosition =
    currentBreadPathIndex > 0 ? breadPathState[currentBreadPathIndex - 1] : null

  yield put(
    signUpCommonActionCreator.setAfterElement(
      afterPosition ? afterPosition.step : null,
    ),
  )

  let pathPosition = 0

  const newBreadPathMapped = breadPathState.map(row => {
    const newMap = {
      ...row,
      disabled: currentBreadPathIndex <= pathPosition,
    }

    pathPosition++

    return newMap
  })

  yield put(
    signUpCommonActionCreator.setCurrentStepName(currentBreadPath.label),
  )

  const newBreadPath = newBreadPathMapped.map(breadPath => {
    const isCurrentBreadPath = breadPath.step === payload
    return {
      ...breadPath,
      disabled: isCurrentBreadPath ? !breadPath.disabled : breadPath.disabled,
    }
  })

  yield put(signUpCommonActionCreator.setBreadPath(newBreadPath))
}

export function* addSeoData({ payload }) {
  if (!payload) return

  const applicationState = yield getApplicationState(),
    seoData = signUpCommonSelectors.getSeoData(applicationState)

  if (window.google_tag_manager) {
    let dataLayer = window.dataLayer || []
    dataLayer.push({
      event: payload.title ? payload.title : seoData.title,
    })
  }

  yield put(
    signUpCommonActionCreator.addSeoData({
      title: payload.title ? payload.title : seoData.title,
    }),
  )
}

export function* createUser() {
  try {
    const applicationState = yield getApplicationState(),
      userSelectedData = signUpCommonSelectors.getUserSelectedData(
        applicationState,
      ),
      { id: cardID, paymentMethodType } = checkoutPageSelectors.getCardData(
        applicationState,
      ),
      { id: addressID } = checkoutPageSelectors.getAddressData(
        applicationState,
      ),
      coupon = signUpCommonSelectors.getCoupon(applicationState),
      giftCardCode = signUpCommonSelectors.getGiftCardCode(applicationState),
      preferencesQuizResponses = signUpCommonSelectors.preferencesQuizResponses(
        applicationState,
      ),
      goals = handleQuizPreferences.filter(
        preferencesQuizResponses.goals,
        'goals',
        true,
      ),
      diets = handleQuizPreferences.filter(
        preferencesQuizResponses.diets,
        'diets',
        true,
      ),
      proteins = handleQuizPreferences.filter(
        preferencesQuizResponses.proteins,
        'proteins',
        true,
      ),
      allergens = handleQuizPreferences.filter(
        (preferencesQuizResponses.allergens || []).filter(a => a.id !== -2),
        'allergens',
        true,
      ),
      specificationsAvoid = handleQuizPreferences.filter(
        preferencesQuizResponses.specificationsAvoid,
        'specificationsAvoid',
        true,
      ),
      {
        days,
        deliveryOption,
        selectedTimesLot,
        startDay,
        selectedPlan,
        flow,
        utm,
      } = userSelectedData,
      subscriptionCharge = false,
      stripeCreditCardId = paymentMethodType !== 'paypal' ? cardID : null,
      payPalPaymentMethodId = paymentMethodType === 'paypal' ? cardID : null,
      selectedDay = days?.length ? days : [startDay?.day],
      userMutation = {
        // name: `${firstname} ${lastname}`,
        plan_variety: flow === 'eat-everything',
        stripe_id: stripeCreditCardId,
        payment_customer_id: payPalPaymentMethodId,
        address_id: addressID,
        start_date: startDay.displayDate,
        time_start: selectedTimesLot.start,
        time_end: selectedTimesLot.end,
        plan: selectedPlan.id,
        utm: utm,
        days: selectedDay,
        delivery_options: deliveryOption,
        coupon:
          coupon && !selectedPlan?.isEconomic
            ? selectedPlan.couponCode || transformCoupon(coupon.couponCode)
            : '',
        trial_discount:
          coupon && coupon.promotion && coupon.promotion.title
            ? coupon.promotion.title
            : '',
        allergens,
        goals: [...new Set(goals)],
        diets: [
          ...new Set(diets.map(diet => ({ id: diet.id, name: diet.name }))),
        ],
        tastes: [...new Set(proteins)],
        dietaryPreferences: [...new Set(specificationsAvoid)],
        giftCardCode,
        plan_with_delivery_fee: selectedPlan.deliveryFee > 0 ? true : false,
      }

    if (!days?.length) {
      logError('User does not have days selected', {
        daysOld: days,
        daysNew: [startDay?.day],
      })
    }

    // TODO: Add recaptcha
    const createUser = yield call(
      GQL.createSignup,
      subscriptionCharge,
      userMutation,
    )
    if (createUser.error) {
      yield put(
        commonCreators.loggingStart({
          event: 'createUserError',
          warning: true,
          data: {
            requestData: userMutation,
            error: createUser.error,
          },
        }),
      )

      yield put(signUpCommonActionCreator.createUserFailed(createUser.error))
    } else {
      yield put(
        commonCreators.loggingStart({
          event: 'createUserSuccess',
          warning: false,
          data: {
            ...createUser,
            requestData: userMutation,
          },
        }),
      )

      yield put(signUpCommonActionCreator.createUser(createUser))

      yield put(signUpCommonActionCreator.startCreateOrder())

      yield identify()
    }
  } catch (error) {
    logError(error)
    yield put(
      commonCreators.loggingStart({
        event: 'createUserError',
        warning: true,
        data: {
          error: error.toString(),
        },
      }),
    )

    const errorMessage =
      error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'oops unexpected error contact with support'

    yield put(signUpCommonActionCreator.createUserFailed(errorMessage))
  }
}

export function* createOrder() {
  try {
    const applicationState = yield getApplicationState(),
      userSelectedData = signUpCommonSelectors.getUserSelectedData(
        applicationState,
      ),
      cartData = preOrderPageSelectors.getCart(applicationState),
      { selectedTimesLot, startDay } = userSelectedData

    const mutationProducts = cartData.products.map(product => {
      return {
        id: product.id,
        qty: product.qty,
        inventoryId:
          (typeof product.data === 'object' && product.data.inventoryId) ||
          undefined,
        batch_id:
          (typeof product.data === 'object' &&
            (product.data.batch_id || product.data.batchId)) ||
          undefined,
      }
    })

    const createOrderMutation = {
      deliveryDate: startDay.date,
      products: mutationProducts,
      start: selectedTimesLot.start,
      end: selectedTimesLot.end,
      override: false,
      includeCookie: false,
      comment: '',
    }

    logInfo('Request to createOrder started', { payload: createOrderMutation })

    const response = yield call(api.createOrder, createOrderMutation)

    logInfo('Request to createOrder response', { payload: response })

    if (
      response &&
      response.data &&
      response.data.createOrder &&
      response.data.createOrder.id &&
      !isNaN(response.data.createOrder.id)
    ) {
      yield put(
        commonCreators.loggingStart({
          event: 'createOrderSuccess',
          warning: false,
          data: {
            ...response.data,
            requestData: createOrderMutation,
          },
        }),
      )
      yield put(signUpCommonActionCreator.createOrder(response.data))
    } else {
      const error = response.data?.createOrder

      yield put(
        commonCreators.loggingStart({
          event: 'createOrderError',
          warning: true,
          data: {
            requestData: createOrderMutation,
            error: error.error,
          },
        }),
      )
      yield put(signUpCommonActionCreator.createOrderFailed(error))
    }
  } catch (error) {
    logError(error, { message: 'error on createOrder' })
    yield put(
      commonCreators.loggingStart({
        event: 'createOrderError',
        warning: true,
        data: {
          error: error.toString(),
        },
      }),
    )
    console.error('error create createOrder: ', error)
    yield put(
      signUpCommonActionCreator.createOrderFailed(
        'oops unexpected error contact with support',
      ),
    )
  }
}

export function* showTopBar({ payload }) {
  yield put(signUpCommonActionCreator.showTopBarSuccess(payload))
}

/**
 * @description Experiment quiz
 */
export function* initPreferencesQuiz() {
  try {
    const quizData = yield call(GQL.getQuizData, {})
    const goalsData = quizData.goals
    const categoryTasteData = quizData.categoryTaste
    const restrictionsData = quizData.restrictionsForSignUp
    const dietsData = quizData.diets
    const dietaryPreferences = quizData.dietaryPreferences

    yield put(
      preferencesQuizActionCreator.preferencesQuizSuccess({
        goals: goalsData ? goalsData.filter(goal => goal.useInSignUp) : null,
        diets: dietsData ? dietsData : null,
        ingredients: restrictionsData ? restrictionsData : null,
        categoryTaste: categoryTasteData ? categoryTasteData : null,
        dietaryPreferences: dietaryPreferences ? dietaryPreferences : null,
      }),
    )
  } catch (error) {
    logError(error)
    yield put(signUpCommonActionCreator.preferencesQuizFailed(error))
  }
}

export function* updateBreadPathStepName({ payload }) {
  if (!payload) return
  yield put(signUpCommonActionCreator.setCurrentStepName(payload))
}

export function* initialExperiments({ payload }) {
  const activeExperiments = getAllActives(payload)

  const initialExperimentObject = {
    list: activeExperiments,
    object: composeObjectWithVariant(payload),
  }

  yield put(
    commonCreators.loggingStart({
      event: 'initialExperiments',
      warning: false,
      data: initialExperimentObject,
      toAnalytics: {
        sendEvent: true,
        adapter: 'bulkEvents',
        data: activeExperiments,
      },
    }),
  )

  yield put(
    commonCreators.trackingStart({
      eventName: 'Signup Experiments init',
      eventData: {
        experiments: initialExperimentObject.list.map(experiment => ({
          experiment_name: experiment.name,
          experiment_branches: experiment.variants,
          experiment_current_branch: experiment.currentVariant,
        })),
      },
    }),
  )

  yield put(
    signUpCommonActionCreator.experimentSuccess(initialExperimentObject),
  )
}

export function* forcedSkipQuiz({ payload }) {
  yield put(signUpCommonActionCreator.forcedSkipQuizSuccess(!!payload))
}

export function* persistState({ payload }) {
  const applicationState = yield getApplicationState()
  const stateForStorage = signUpCommonSelectors.getStateForStorage(
    applicationState,
  )
  persistStateUtil(payload.sessionId, stateForStorage)
}

export function* recoverState({ payload }) {
  try {
    yield put(
      signUpCommonActionCreator.setUserSelectedData(
        payload.data.userSelectedData,
      ),
    )
    yield put(mealStepPageCreators.setCartData(payload.data.cartData))
    yield put(
      DeliveryPageCreators.deliveryOptionCreators.start(
        payload.data.deliveryOptionsData,
      ),
    )
    yield put(
      signUpCommonCreators.preferencesQuizSetResponse(
        payload.data.preferencesQuizData,
      ),
    )
    yield put(checkoutPageActionCreator.setAddresses(payload.data.addressData))
    yield put(
      signUpCommonCreators.forcedSkipQuizStart(payload.data.forceSkipQuiz),
    )
  } catch (error) {
    logError(error, ['state management- recover from local storage'])
  }
}

export function* confirmNewZipcode({ payload }) {
  yield put(
    signUpCommonActionCreator.setUserSelectedDataStart({
      startDay: {},
      days: [],
    }),
  )
  yield put(mealsStepActionCreator.resetCart())
  yield put(signUpCommonActionCreator.persistState(payload))
}

export function* initChefsList() {
  try {
    const applicationState = yield getApplicationState()
    const { zipcode, startDay } = signUpCommonSelectors.getUserSelectedData(
      applicationState,
    )
    const date = startDay.date

    const chefsListSuccess = signUpCommonSelectors.getChefsListSuccess(
      applicationState,
    )

    const chefsListData = signUpCommonSelectors.getChefsListData(
      applicationState,
    )

    const mealsData = yield !chefsListSuccess
      ? call(GQL.getSignUpMenu, { zipcode, date })
      : []

    if (!Array.isArray(mealsData)) {
      throw new Error('invalid data')
    }

    const allChefs =
      (chefsListSuccess && chefsListData && chefsListData.allChefs) ||
      chefsListUtils.getChefsFromMeals(mealsData) ||
      []

    yield put(
      signUpCommonActionCreator.successChefsList({
        allChefs,
      }),
    )

    yield put(signUpCommonActionCreator.updateChefsList())
  } catch (error) {
    logError(error)
    yield put(signUpCommonActionCreator.failedChefsList(error))
  }
}

export function* updateChefsList() {
  try {
    const applicationState = yield getApplicationState()

    const chefsListSuccess = signUpCommonSelectors.getChefsListSuccess(
      applicationState,
    )

    if (!chefsListSuccess) return null

    const chefsListData = signUpCommonSelectors.getChefsListData(
      applicationState,
    )

    const preferencesQuizResponses = signUpCommonSelectors.preferencesQuizResponses(
      applicationState,
    )

    const allChefs =
      (chefsListData &&
        Array.isArray(chefsListData.allChefs) &&
        chefsListData.allChefs) ||
      []

    const filters =
      preferencesQuizResponses &&
      preferencesQuizResponses.proteins &&
      preferencesQuizResponses.proteins.map(protein => protein.name)

    const filteredChefs = chefsListUtils.filterChefsListByLabels(
      allChefs,
      filters,
    )

    yield put(
      signUpCommonActionCreator.successChefsList({
        chefs: filteredChefs,
        length: filteredChefs.length,
        filters: filters || [],
      }),
    )
  } catch (error) {
    logError(error)
    yield put(signUpCommonActionCreator.failedChefsList(error))
  }
}

export function* loadChefDetail({ payload }) {
  try {
    const chef = yield call(api.getChefDetail, payload)

    yield put(signUpCommonActionCreator.loadChefDetail(chef.data.chef))
  } catch (error) {
    logError(error)
    yield put(signUpCommonActionCreator.loadChefDetailFailed(error))
  }
}

export function* loadMenuSortBy({ payload }) {
  try {
    const applicationState = yield getApplicationState()
    const userSelectedData = signUpCommonSelectors.getUserSelectedData(
      applicationState,
    )
    const { selectedPlan } = userSelectedData

    const menu = yield call(GQL.getMenuSortBy, {
      ...payload,
      planSegmentId: selectedPlan?.segmentId,
    })

    yield put(signUpCommonActionCreator.loadMenuSortBy({ menu }))
  } catch (error) {
    logError(error)
    yield put(signUpCommonActionCreator.loadMenuSortByFailed(error))
  }
}

export function* setDDOToggles({ payload }) {
  try {
    yield put(
      commonCreators.trackingStart({
        eventName: EVENTS.ddoTogglesLoaded,
        eventData: {
          toggles: payload.map(toggle => toggle.name),
        },
      }),
    )
  } catch (error) {
    logError(error)
  }
}

export default function* rootInitialSaga() {
  yield all([
    takeEvery(signUpCommonActions.INIT_DATA.start, initSignUpData),
    takeEvery(signUpCommonActions.APPLY_COUPON.start, applyCoupon),
    takeEvery(signUpCommonActions.BREAD_PATH.start, fetchBreadPath),
    takeEvery(signUpCommonActions.USER_SELECTED_DATA.start, setUserData),
    takeEvery(signUpCommonActions.SEO_DATA.startAddSeoData, addSeoData),
    takeEvery(signUpCommonActions.CREATE_USER.start, createUser),
    takeEvery(signUpCommonActions.CREATE_ORDER.start, createOrder),
    takeEvery(signUpCommonActions.SHOW_TOP_BAR.start, showTopBar),
    takeEvery(
      signUpCommonActions.INIT_PREFERENCES_QUIZ.start,
      initPreferencesQuiz,
    ),
    takeEvery(
      signUpCommonActions.BREAD_PATH.startSetCurrentStepName,
      updateBreadPathStepName,
    ),
    takeEvery(signUpCommonActions.EXPERIMENTS.start, initialExperiments),
    takeEvery(signUpCommonActions.FORCED_SKIP_QUIZ.start, forcedSkipQuiz),
    takeEvery(signUpCommonActions.CONFIRM_NEW_ZIPCODE.start, confirmNewZipcode),
    takeEvery(signUpCommonActions.PERSIST_STATE.start, persistState),
    takeEvery(signUpCommonActions.RECOVER_STATE.start, recoverState),
    takeEvery(signUpCommonActions.CHEFS_LIST.start, initChefsList),
    takeEvery(signUpCommonActions.CHEFS_LIST.update, updateChefsList),
    takeEvery(signUpCommonActions.LOAD_CHEF_DETAIL.start, loadChefDetail),
    takeEvery(signUpCommonActions.LOAD_MENU_SORT_BY.start, loadMenuSortBy),
    takeLatest(signUpCommonActions.DDO_TOGGLES.set, setDDOToggles),
  ])
}
