import React, { useState } from 'react'
import Modal from '../../../components/NewModal'
import { useVariant } from '@unleash/proxy-client-react'
import classnames from 'classnames'
import { CuiIcon, CuiTooltip } from 'front-lib'
import { formatNumberToCurrency } from 'src/utils/priceUtils'
import useExperiment from 'src/hooks/useExperiment'
import { EXPERIMENTS } from 'src/constants/experiments'

import caretRight from '../../../../../assets/icons/caret-black-right.png'
import { pageNames } from 'src/screens/signup/constants'
import { getGiftCardLabel } from 'src/utils/giftcardUtils'

const OrderSummaryCui = ({
  coupon,
  startDay,
  selectedPlan,
  checkoutAccount,
  giftcardData,
  sendTracking = () => {},
}) => {
  const [openModal, setOpenModal] = useState(false)

  const variant = useVariant('CHECKOUT_DISCOUNT_PRICE_COLOR')
  const newColors = variant && variant.enabled && variant.name === 'TREATMENT'

  const { isTreatment: orderSummaryRedesign} = useExperiment(
    EXPERIMENTS.checkoutOrderSummaryRedesign,
    { trackOnMount: true }
  )

  const taxTotalAmount = checkoutAccount?.tax + checkoutAccount?.deliveryFee

  const handleOpenModal = value => {
    if (value) {
      sendTracking({
        eventName: 'Sign Up - Checkout Step - View Summary Detail',
        eventData: {
          page_name: pageNames.CHECKOUT,
        },
      })
    }

    setOpenModal(value)
  }

  return (
    <div className="order-summary-container-cui">
      <Modal
        smallSize
        isOpen={openModal}
        onRequestClose={() => {
          handleOpenModal(false)
        }}
      >
        <div className="order-summary-modal-container">
          <h3 className="rebrand">Taxes and fees</h3>
          <div
            className={classnames('table', {
              newColors,
            })}
          >
            <div className="table-item__checkout">
              <p className="rebrand">Delivery fee</p>
              <p className="rebrand bold_checkout">
                {formatNumberToCurrency(checkoutAccount.deliveryFee)}
              </p>
            </div>
            <div className="table-item__checkout">
              <p className="rebrand">Sales taxes and service fee</p>
              <p className="rebrand bold_checkout">
                {formatNumberToCurrency(checkoutAccount.tax)}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <h3 className="rebrand">Order Summary</h3>
      <div
        className={classnames('table', {
          newColors,
        })}
      >
        <div className="table-item__checkout border-table_checkout">
          <p className="rebrand">First Delivery</p>
          <p className="rebrand bold_checkout">{startDay.label}</p>
        </div>

        <div className="table-item__checkout">
          <p className="rebrand">
            {`${selectedPlan.mealsPerDelivery}`} meals /{' '}
            {`${selectedPlan.deliveries}`} delivery per week
          </p>
          <p className="rebrand bold_checkout">
            {formatNumberToCurrency(checkoutAccount.planPrice)}
          </p>
        </div>

        {
          <div className="table-item__checkout">
            <p className="rebrand">Premium Meals</p>
            <p className="rebrand bold_checkout">
              {formatNumberToCurrency(checkoutAccount.premiumMealsFee)}
            </p>
          </div>
        }

        {checkoutAccount?.extraMealsFee > 0 && (
          <div className="table-item__checkout">
            <p className="rebrand row">
              Extra meals
              <CuiTooltip small tip={<div>Extra meals price.</div>}>
                <CuiIcon name="infoEmptyCircled" />
              </CuiTooltip>
            </p>
            <p className="rebrand bold_checkout">
              {formatNumberToCurrency(checkoutAccount.extraMealsFee)}
            </p>
          </div>
        )}
        {coupon && coupon.promotion && (
          <div
            className={classnames('table-item__checkout', {
              'border-table_checkout':
                !orderSummaryRedesign && !checkoutAccount?.giftcardCredit,
            })}
          >
            <p className="rebrand bold_checkout">{`Discount (${coupon.userCoupon ||
              coupon.couponCode})`}</p>
            <p className="rebrand discount">
              - {formatNumberToCurrency(checkoutAccount.discount)}
            </p>
          </div>
        )}
        {checkoutAccount?.giftcardCredit > 0 && (
          <div
            className={classnames('table-item__checkout', {
              'border-table_checkout': !orderSummaryRedesign,
            })}
          >
            <p className="rebrand bold_checkout">
              {getGiftCardLabel(giftcardData?.giftcardCode)}
            </p>
            <p className="rebrand discount">
              - {formatNumberToCurrency(checkoutAccount.giftcardCredit)}
            </p>
          </div>
        )}
        {orderSummaryRedesign && (
          <div className="table-item__checkout border-table_checkout">
            <div>
              <p className="rebrand taxes-fees">Taxes and fees</p>
              <CuiTooltip
                className="new-order-summary-tooltip"
                small
                top
                tip={<div>Taxes, delivery, and other fees.</div>}
              >
                <CuiIcon
                  name="info"
                  className="new-order-summary-tooltip-icon"
                />
              </CuiTooltip>
            </div>
            <p className="rebrand bold_checkout">
              {formatNumberToCurrency(taxTotalAmount)}
            </p>
          </div>
        )}
        <div className="table-item__checkout">
          <p className={classnames('rebrand', { newColors })}>
            FIRST WEEK TOTAL
          </p>
          <p className="rebrand total-number">
            {formatNumberToCurrency(checkoutAccount.total)}
          </p>
        </div>

        {!orderSummaryRedesign && (
          <div className="table-item__checkout details">
            <p className={classnames('rebrand', { newColors })}>
              Includes taxes and fees
            </p>
            <p className="rebrand action" onClick={() => handleOpenModal(true)}>
              <span className="summary_view_details">
                View details <img src={caretRight} alt=">" />
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderSummaryCui
