import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as selectorCommons from 'src/redux/ducks/commons/selectors'

import * as creatorCommons from 'src/redux/ducks/commons/creators'

import HomePageCui from './HomePageCui'

import { useLocation, useNavigate } from 'react-router-dom'
import { PATHS_NAME } from 'src/constants/routes'
import useAppSearchParams from 'src/hooks/useAppSearchParams'
import { logView } from 'src/utils/logError'

function HomePageContainer({
  askEmail,
  zipcode,
  email,
  isValidZipCode,
  initialZipcode,
  isValizZipCodeLoading,
  validZipCodeError,
  actions: { validZipCodeStart, validZipCodeReset },
}) {
  const { getAllSearchParams } = useAppSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const paramsObj = getAllSearchParams();


    logView(PATHS_NAME[location?.pathname], {
      email: paramsObj?.email || email,
      zipCode: paramsObj?.zipcode || zipcode,
      params: paramsObj,
      viewName: PATHS_NAME[location?.pathname],
      currentPath: location?.pathname,
      previousPath: null
    });
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const continueAction = useCallback(
    (zipcode, email) => {
      validZipCodeStart({
        zipcode,
        email,
      })
    },
    [validZipCodeStart],
  )

  useEffect(() => {
    if (!isValidZipCode) {
      return
    }

    const { plan, coupon } = getAllSearchParams()
    const newSearchParams = new URLSearchParams()

    newSearchParams.set('zipcode', zipcode)

    if (email) {
      newSearchParams.set('email', email)
    }

    if (coupon) {
      newSearchParams.set('coupon', coupon)
    }

    if (plan) {
      newSearchParams.set('plan', plan)
    }

    const paramsObj = getAllSearchParams();

    logView(`Leave ${PATHS_NAME[window.location.pathname]}`, {
      email: paramsObj?.email || email,
      zipCode: paramsObj?.zipcode || zipcode,
      params: paramsObj,
      viewName: `Leave ${PATHS_NAME[window.location.pathname]}`,
      currentPath: window.location.pathname,
      previousPath: null
    });

    navigate(`/?${newSearchParams.toString()}`)

  }, [email, getAllSearchParams, isValidZipCode, navigate, zipcode])

  return (
    <HomePageCui
      askEmail={askEmail}
      continueAction={continueAction}
      validZipCodeReset={validZipCodeReset}
      initialZipcode={initialZipcode}
      isLoading={isValizZipCodeLoading}
      validZipCodeError={validZipCodeError}
    />
  )
}

const mapStateToProps = state => ({
  isValizZipCodeLoading: selectorCommons.checkValidZipCodeLoading(state),
  isValidZipCode: selectorCommons.checkValidZipCode(state),
  validZipCodeError: selectorCommons.getValidZipCodeError(state),
  email: selectorCommons.getEmail(state),
  zipcode: selectorCommons.getZipCode(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      validZipCodeStart: creatorCommons.validZipCodeStart,
      validZipCodeReset: creatorCommons.validZipCodeReset,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer)
