import {
  B2B_MONTHLY_MAX_DISCOUNT,
  DEFAULT_DISCOUNTS,
  LOW_PRICE_FIXED_AMOUNT_PER_MEAL,
  TYPES,
} from 'src/constants/promotions'
import {
  calculateDiscounts,
  getFixedPricePerMeal,
  getOrderPrices,
} from './priceUtils'
import { roundToTwo, roundToTwoValues, sumObjectValues } from './mathUtils'
import { logError } from './logError'

const discountPromotion = ({ plan, meals, discount }) => {
  const { planPrice, premiumMealsFee, extraMealsFee } = getOrderPrices({
    plan,
    meals,
  })

  const {
    planDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  } = calculateDiscounts({
    planPrice,
    premiumMealsFee,
    extraMealsFee,
    discount,
  })

  return {
    planDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  }
}

const vipCreditPromotion = ({ plan, meals, discount }) => {
  const {
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
  } = getOrderPrices({
    plan,
    meals,
  })

  const {
    planDiscount,
    deliveryDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  } = calculateDiscounts({
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
    discount,
  })

  return {
    planDiscount,
    premiumMealsDiscount,
    deliveryDiscount,
    extraMealsDiscount,
  }
}

export const lowPricePromotion = ({ plan }) => {
  const mealPlanSize = plan.deliveries * plan.mealsPerDelivery

  const planDiscount = getFixedPricePerMeal(
    plan.price,
    mealPlanSize,
    LOW_PRICE_FIXED_AMOUNT_PER_MEAL,
  )

  return { planDiscount }
}

const deliveryAndPlanB2B = ({ plan, meals, discount, promotionTitle }) => {
  const {
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
  } = getOrderPrices({
    plan,
    meals,
  })

  const limit = B2B_MONTHLY_MAX_DISCOUNT[promotionTitle]

  const {
    planDiscount,
    deliveryDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  } = calculateDiscounts({
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
    discount,
    limit,
  })

  return {
    planDiscount,
    premiumMealsDiscount,
    deliveryDiscount,
    extraMealsDiscount,
  }
}

const discountAndFreeShipping = ({ plan, meals, discount }) => {
  const {
    planPrice,
    deliveryFee,
    premiumMealsFee,
    extraMealsFee,
  } = getOrderPrices({
    plan,
    meals,
  })

  const {
    planDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  } = calculateDiscounts({
    planPrice,
    premiumMealsFee,
    extraMealsFee,
    discount,
  })

  return {
    planDiscount,
    premiumMealsDiscount,
    deliveryDiscount: deliveryFee,
    extraMealsDiscount,
  }
}

const compoundPromotion = ({ plan, meals, discount }) => {
  const { planPrice, premiumMealsFee, extraMealsFee } = getOrderPrices({
    plan,
    meals,
  })

  const {
    planDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  } = calculateDiscounts({
    planPrice,
    premiumMealsFee,
    extraMealsFee,
    discount,
  })

  return {
    planDiscount,
    premiumMealsDiscount,
    extraMealsDiscount,
  }
}

const strategies = {
  [TYPES.discountPromotion]: discountPromotion,
  [TYPES.vipCreditPromotion]: vipCreditPromotion,
  [TYPES.lowPricePromotion]: lowPricePromotion,
  [TYPES.deliveryAndPlanB2B]: deliveryAndPlanB2B,
  [TYPES.discountAndFreeShipping]: discountAndFreeShipping,
  [TYPES.compoundPromotion]: compoundPromotion,
}

export const getPromotionDiscounts = ({ plan, meals = [], promotion }) => {
  try {
    if (!promotion) {
      throw Error('Promotion must be required')
    }
    let discounts = DEFAULT_DISCOUNTS
    let discountsRounded = DEFAULT_DISCOUNTS

    const promotionType = promotion.promotionType
    const strategy = strategies[promotionType]

    if (strategy) {
      discounts = strategies[promotionType]({
        plan,
        meals,
        discount: promotion.discount,
        promotionTitle: promotion.title,
      })

      discountsRounded = roundToTwoValues(discounts)
    }

    const credit = getPromotionCredit({ credit: promotion.credit })

    /**
     * TODO: This is a temporary solution to fix the issue with the CAP
     * @description The issue is that the CAP is not taken into account when adding premium meals
     */
    const totalDiscount = isOnlyPlanDiscount({ promotionId: promotion.id })
      ? roundToTwo(discountsRounded?.planDiscount)
      : roundToTwo(sumObjectValues(discountsRounded))

    return {
      ...DEFAULT_DISCOUNTS,
      ...discountsRounded,
      credit,
      totalDiscount,
    }
  } catch (error) {
    logError(error)
    return DEFAULT_DISCOUNTS
  }
}

export const getPromotionCredit = ({ credit }) => {
  return +credit?.amount || 0
}

const MULTI_WEEK_PROMOTION_IDS = [326, 327, 328, 329, 330, 331, 332, 334, 335, 416, 417, 418, 419, 420, 421, 422, 423, 424, 425]

export const isOnlyPlanDiscount = ({ promotionId }) => MULTI_WEEK_PROMOTION_IDS.includes(promotionId)
