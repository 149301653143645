export const NPC_CONFIG = {
  '0': {
    noPopulateCart: false,
    sortMenu: true,
    displayHighlightedRow: 'default',
  },
  '1': {
    noPopulateCart: true,
    sortMenu: true,
    displayHighlightedRow: 'default',
  },
  '2': {
    noPopulateCart: true,
    sortMenu: false,
    displayHighlightedRow: 'always',
  },
  '3': {
    noPopulateCart: true,
    sortMenu: true,
    displayHighlightedRow: 'never',
  },
}
